import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import BankDepositItem from '../components/Deposit/BankDepositItem'
import { ApplicationState } from '../store'
import { Col, Row, Typography } from 'antd'

const { Text } = Typography

const BankDeposit = () => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	if (depositToBanks.length <= 0) {
		return <Redirect to="/deposit" />
	}
	return (
		<>
			<Row gutter={[{ xs: 16, sm: 16, xxl: 24}, { xs: 16, sm: 16, xxl: 24}]}>
				<Col span={24}>
					<div className="notice-card">
						<Text type="warning"><i className="fa-solid fa-bullhorn" /></Text>
						<Text className="notice-card-text">
							ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
						</Text>
					</div>
				</Col>
				<Col span={24}>
					<div className="notice-card">
						<Text type="danger"><i className="fa-solid fa-message-exclamation" /></Text>
						<Text className="notice-card-text">
							สามารถโอนเงินเข้าธนาคารโดยใช้ <Text type="danger"><strong>ชื่อบัญชีและเลขบัญชี</strong></Text> ที่สมัครโอนเข้ามาเท่านั้น
						</Text>
					</div>
				</Col>
				{depositToBanks.map((bank) => (
					<BankDepositItem bank={bank} key={bank.accountNumber} />
				))}
			</Row>
		</>
	)
}

export default BankDeposit
