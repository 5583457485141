import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { Col, Row } from 'antd'
interface WalletMobileMenuProps {
	lineLink: string
	wheel: boolean
	commission: boolean
	showMenuSupport?: boolean
	ranking?: boolean
}
const WalletMobileMenu: FC<WalletMobileMenuProps> = ({ lineLink, wheel, commission, showMenuSupport, ranking }) => (
	<Row gutter={[32, 16]} className="menu-mobile-wrapper">
		<Col xs={6} md={3} lg={4}>
			<Link to={makePath('/deposit')} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/3d/deposit.svg`} alt="deposit icon" />
				</div>
				เติมเงิน
			</Link>
		</Col>
		<Col xs={6} md={3} lg={4}>
			<Link to={makePath('/withdraw')} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/3d/withdraw.svg`} alt="withdraw icon" />
				</div>
				ถอนเงิน
			</Link>
		</Col>
		<Col xs={6} md={3} lg={4}>
			<Link to={makePath('/promotion')} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/3d/promo.svg`} alt="promo icon" />
				</div>
				โปรโมชั่น
			</Link>
		</Col>
		<Col xs={6} md={3} lg={4}>
			<Link to={makePath('/transactions')} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/3d/history.svg`} alt="history icon" />
				</div>
				ประวัติ
			</Link>
		</Col>
		<Col xs={6} md={3} lg={4}>
			<Link to={makePath('/profile')} className="menu-mobile">
				<div className="menu-mobile-icon">
					<img src={`/icons/3d/profile.svg`} alt="profile icon" />
				</div>
				โปรไฟล์
			</Link>
		</Col>
		{commission ? (
			<Col xs={6} md={3} lg={4}>
				<Link to={makePath('/commission')} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/commission.svg`} alt="commission icon" />
					</div>
					คอมมิชชั่น
				</Link>
			</Col>
		) : (
			<Col xs={6} md={3} lg={4}>
				<Link to={makePath('/affiliate')} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/affiliate.svg`} alt="affiliate icon" />
					</div>
					ชวนเพื่อน
				</Link>
			</Col>
		)}

		{wheel && (
			<Col xs={6} md={3} lg={4}>
				<Link to={makePath('/lucky-wheel')} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/wheel.svg`} alt="wheel icon" />
					</div>
					กงล้อ
				</Link>
			</Col>
		)}

		{!showMenuSupport && (
			<Col xs={6} md={3} lg={4}>
				<Link to={makePath('/support')} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/chat.svg`} alt="chat icon" />
					</div>
					แจ้งปัญหา
				</Link>
			</Col>
		)}

		{lineLink && showMenuSupport && (
			<Col xs={6} md={3} lg={4}>
				<a href={lineLink} target="_blank" rel="noreferrer" className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/chat.svg`} alt="chat icon" />
					</div>
					แจ้งปัญหา
				</a>
			</Col>
		)}

		{ranking && (
			<Col xs={6} md={3} lg={4}>
				<Link to={makePath('/ranking')} className="menu-mobile">
					<div className="menu-mobile-icon">
						<img src={`/icons/3d/ranking.svg`} alt="affiliate icon" />
					</div>
					จัดอันดับ
				</Link>
			</Col>
		)}

	</Row>
)

export default WalletMobileMenu
