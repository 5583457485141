// import { useFormik } from 'formik'
import qs from 'querystring'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import RegisterStep1 from '../components/Register/RegisterStep1'
import RegisterStep2 from '../components/Register/RegisterStep2'
import RegisterStep3 from '../components/Register/RegisterStep3'
import api from '../helpers/api'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { ApplicationState } from '../store'
import { setUser } from '../store/user/user.actions'
import { Button, Card, Form, Typography } from 'antd'
interface RegisterResponse {
	token: string
}

const dataRegister = {
	phone: '',
	bankCode: '',
	bankNumber: '',
	firstName: '',
	lastName: '',
	firstNameEN: '',
	lastNameEN: '',
	password: '',
	line: '',
	autoBonus: '',
	knowFrom: '',
	affiliateCode: '',
	captcha: ''
}

const { Text } = Typography;

const Register = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const location = useLocation()
	const query = qs.parse(location.search.substr(1))
	const refCode = query.ref ? query.ref.toString() : ''

	let autoBonus = 'off'
	if (refCode && prefix) {
		const promotion = prefix.promotions.find((v) => v.limit === 'once')
		if (promotion) {
			autoBonus = promotion.key
		}
	}

	const [step, setStep] = useState<number>(1)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [bankCode, setBankCode] = useState<string>('')
	const [knowFrom, setKnowFrom] = useState<string>('')
	const [affiliateCode, setAffiliateCode] = useState<string>('')

	const handleSubmit = async () => {
		if (step === 1) {
			form.validateFields()
				.then(async (values: any) => {
					values.phone = values.phone.replace(/-|_/g, '')
					dataRegister.phone = values.phone
					if (values.phone.length !== 10) {
						toast.error('เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร')
						return
					}
					try {
						setIsSubmitting(true)
						await api.post('/register/step-1', values)
						setStep(2)
					} catch ({ message }) {
						toast.error(message as string)
					} finally {
						setIsSubmitting(false)
					}
				}).catch((errorInfo) => { });

		} else if (step === 2) {
			form.validateFields()
				.then(async (values: any) => {
					const thaiRegex = /^[ก-๙ ]+$/g
					if (!values.firstName.trim().match(thaiRegex)) {
						toast.error('ชื่อต้องเป็นภาษาไทยเท่านั้น')
						return
					}
					if (!values.lastName.trim().match(thaiRegex)) {
						toast.error('นามสกุลต้องเป็นภาษาไทยเท่านั้น')
						return
					}
					if (values.bankCode === '') {
						toast.error('กรุณาเลือกธนาคาร')
						return
					}

					dataRegister.bankCode = values.bankCode
					dataRegister.bankNumber = values.bankNumber
					dataRegister.firstName = values.firstName
					dataRegister.lastName = values.lastName
					dataRegister.firstNameEN = values.firstNameEN || ""
					dataRegister.lastNameEN = values.lastNameEN || ""

					try {
						setIsSubmitting(true)
						await api.post('/register/step-2', values)

						setStep(3)
					} catch ({ message }) {
						toast.error(message as string)
					} finally {
						setIsSubmitting(false)
					}
				}).catch((errorInfo) => { });
		} else if (step === 3) {
			form.validateFields()
				.then(async (values: any) => {
					if (values.password.length < 6) {
						toast.error('รหัสผ่านต้องมีความยาวมากกว่า 6 ตัวอักษร')
						return
					}

					const alphaRegex = /(?=.*[a-zA-Z])/g
					if (!values.password.match(alphaRegex)) {
						toast.error('รหัสผ่านต้องมีตัวอักษรอย่างน้อย 1 ตัว')
						return
					}

					const numberRegex = /(?=.*[0-9])/g
					if (!values.password.match(numberRegex)) {
						toast.error('รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว')
						return
					}

					dataRegister.password = values.password
					dataRegister.line = values.line
					dataRegister.autoBonus = values.autoBonus
					dataRegister.knowFrom = values.knowFrom ? values.knowFrom : knowFrom
					if (values.knowFrom2) {
						dataRegister.knowFrom = dataRegister.knowFrom + ',' + values.knowFrom2
					}
					dataRegister.affiliateCode = values.affiliateCode
					// dataRegister.captcha = values.captcha
					if (window.location.hostname === 'localhost' ||
						window.location.hostname === "msn.bet" ||
						window.location.hostname === "msn1.bet" ||
						window.location.hostname === "msn2.bet") {
						dataRegister.captcha = values.captcha
					} else {
						dataRegister.captcha = tokenTurnstile
					}

					try {
						setIsSubmitting(true)
						const res = await api.post<void, RegisterResponse>('/register', dataRegister)

						AuthService.setToken(res.token)
						const member = await AuthService.getMemberInfo(true)
						dispatch(setUser(member))

						setStep(4)
					} catch ({ message }) {
						setIsSubmitting(false)
						toast.error(message as string)
					}
				}).catch((errorInfo) => { });
		}
	};
	const [tokenTurnstile, setTokenTurnstile] = useState<string>('')
	const setTurnstile = (token: any) => {
		if (token) {
			// form.setFieldsValue({ "captcha": token })
			setTokenTurnstile(token)
		}
	}

	useEffect(() => {
		if (form) {
			dataRegister.autoBonus = autoBonus
			dataRegister.knowFrom = refCode !== "" ? 'friend' : ""
			dataRegister.affiliateCode = refCode
			setAffiliateCode(refCode)
			setKnowFrom(dataRegister.knowFrom)
			if (dataRegister.knowFrom) {
				form.setFieldsValue({ "knowFrom": dataRegister.knowFrom })
			}
		}
		return () => {
			setTokenTurnstile("")
		}
	}, [form]) //eslint-disable-line

	if (!prefix) {
		return <div>ไม่สามารถสมัครสมาชิกได้ในขณะนี้</div>
	}

	return (
		<>
			<Form
				form={form}
				name="registerForm"
				layout="vertical"
				hideRequiredMark>
				{step === 1 && <RegisterStep1 isSubmitting={isSubmitting} handleSubmit={handleSubmit} />}
				{step === 2 && (
					<RegisterStep2 bankCode={bankCode} setBankCode={setBankCode} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
				)}
				{step === 3 && (
					<RegisterStep3
						knowFrom={knowFrom}
						setKnowFrom={setKnowFrom}
						defaultAffiliateCode={affiliateCode}
						promotions={prefix.promotions}
						isSubmitting={isSubmitting}
						handleSubmit={handleSubmit}
						setTurnstile={setTurnstile}
						recaptcha={prefix.recaptcha}
					/>
				)}
				{step === 4 && (
					<Card title="สมัครสมาชิกสำเร็จแล้ว" className="card-title-center">
						<p style={{ marginTop: 0 }}>คุณสามารถใช้เบอร์โทรศัพท์และรหัสผ่าน<br />ด้านล่างเพื่อเข้าสู่ระบบ</p>
						<Card type="inner" size="small" style={{ marginBottom: '16px' }}>เบอร์โทรศัพท์: <span style={{ color: 'var(--color-primary)' }}>{dataRegister.phone}</span></Card>
						<Card type="inner" size="small">รหัสผ่าน: <span style={{ color: 'var(--color-primary)' }}>{dataRegister.password}</span></Card>
						<Link to={makePath('/wallet')}>
							<Button
								type="primary"
								shape="round"
								block
								style={{ marginTop: '16px' }}
							>
								เข้าสู่หน้าหลัก
							</Button>
						</Link>
					</Card>
				)}
			</Form>

			{step !== 4 && (
				<div style={{ textAlign: 'center' }}>
					<Text>ต้องการเข้าสู่ระบบใช่ไหม? <Link to={makePath('/login')}>เข้าสู่ระบบ</Link></Text>
				</div>
			)}
		</>
	)
}

export default Register
