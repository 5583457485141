import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getBankName, getBankColor } from '../helpers/bank'
import { ApplicationState } from '../store'
import { Button, Col, Divider, Row, Statistic, Typography } from 'antd'
import { makePath } from '../helpers/path'
import { CopyOutlined } from '@ant-design/icons';

const { Text } = Typography

const Profile = () => {
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const info = userState.info
	const prefix = prefixState.info

	if (!info) {
		return <div>ไม่พบโปรไฟล์ของคุณในขณะนี้</div>
	}

	const copyGameUsername = () => {
		navigator.clipboard.writeText(
			info.game?.meta?.applicationUsername || info.game?.username || 'username'
		)
		toast.success('คัดลอกชื่อผู้ใช้งานสำเร็จ')
	}
	const copyGamePassword = () => {
		navigator.clipboard.writeText(info.game.password || 'password')
		toast.success('คัดลอกรหัสผ่านสำเร็จ')
	}

	const isAccount = (prefix?.game !== "ambbet" && prefix?.game !== "wingbet" && prefix?.game !== '2goalbet') &&
		(
			<>
				<div style={{ marginBottom: 6, color: "rgba(255, 255, 255, 0.75)" }}>บัญชีผู้ใช้งาน</div>
				<Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
					<Col xs={24} sm={12}>
						<Statistic title="Username" formatter={(value) => value} value={info.game?.meta?.applicationUsername || info.game?.username} />
						<Button
							type="link"
							icon={<CopyOutlined />}
							onClick={copyGameUsername}
							style={{ paddingLeft: 0 }}
						>
							คัดลอก
						</Button>
					</Col>
					<Col xs={24} sm={12}>
						<Statistic title="Password" formatter={(value) => value} value={info.game.password} />
						<Button
							type="link"
							icon={<CopyOutlined />}
							onClick={copyGamePassword}
							style={{ paddingLeft: 0 }}
						>
							คัดลอก
						</Button>
					</Col>
				</Row>
				<Divider />
			</>
		)
	return (
		<Row justify="center">
			<Col xs={24} md={16} xl={12}>
				<div className="form-card">
					<div className="form-card-body">
						<Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
							<Col xs={24} sm={12}>
								<Statistic title="ชื่อ-นามสกุล" value={info.firstName + ' ' + info.lastName} />
							</Col>
							<Col xs={24} sm={12}>
								<Statistic title="เบอร์โทรศัพท์" formatter={(value) => value} value={info.phone} />
							</Col>
						</Row>
						<Divider />
						{isAccount}
						<Text style={{ marginBottom: 6, display: 'block' }}>ข้อมูลบัญชีธนาคาร</Text>
						<div>
							<div className="bank-card">
								<div
									className="bank-card-logo"
									style={{
										backgroundColor: getBankColor(info.bankCode),
										boxShadow: `${getBankColor(info.bankCode)} 0 8px 12px -10px`
									}}
								>
									<img
										width={56}
										src={require(`../assets/banks/${info.bankCode.toLowerCase()}.svg`).default}
										alt={getBankName(info.bankCode)}
									/>

								</div>
								<div className="bank-card-info">
									<div><small>{getBankName(info.bankCode)}</small></div>
									<div className="bank-deposite-account">{info.bankNumber}</div>
									<div><small>{info.firstName} {info.lastName}</small></div>
								</div>
							</div>
						</div>
						<Link to={makePath('/logout')} >
							<Button shape="round" style={{ marginTop: 24 }} block>ออกจากระบบ</Button>
						</Link>
					</div>
				</div>
			</Col>
		</Row>
	)
}

export default Profile
