import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import WalletDepositItem from '../components/Deposit/WalletDepositItem'
import { ApplicationState } from '../store'
import { Col, Row, Typography } from 'antd'

const { Text } = Typography

const WalletDeposit = () => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)

	const depositToTruewallets = depositToList.filter((bank) => bank.bank === 'truewallet')
	if (depositToTruewallets.length <= 0) {
		return <Redirect to="/deposit" />
	}
	return (
		<Row gutter={[{ xs: 16, sm: 16, xxl: 24}, { xs: 16, sm: 16, xxl: 24}]}>
			<Col span={24}>
				<div className="notice-card">
					<Text type="warning"><i className="fa-solid fa-bullhorn" /></Text>
					<Text className="notice-card-text">
						ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
					</Text>
				</div>
			</Col>
			<Col span={24}>
				<div className="notice-card">
					<Text type="danger"><i className="fa-solid fa-message-exclamation" /></Text>
					<Text className="notice-card-text">
						สามารถโอนเงินเข้าทรูมันนี่วอลเล็ทโดยใช้ <Text type="danger"><strong>ชื่อและเบอร์โทรศัพท์</strong></Text> ที่สมัครโอนเข้ามาเท่านั้น
					</Text>
				</div>
			</Col>
			{depositToTruewallets.map((bank) => (
				<WalletDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</Row>
	)
}

export default WalletDeposit
