// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { truewalletVoucherDeposit } from '../../services/deposit'
import { Button, Col, Form, Modal, Input, Image, Row, Typography } from 'antd'

const { Text } = Typography

interface TruewalletVoucherProps {
	isOpen: boolean
	onClose: () => void
}

const TruewalletVoucher: FC<TruewalletVoucherProps> = ({ isOpen, onClose }) => {
	const [form] = Form.useForm();

	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [visible, setVisible] = useState<boolean>(false);

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				try {
					const res = await truewalletVoucherDeposit({
						voucher: values.voucher,
					})

					toast.success(res.message as string)
					onClose()
				} catch ({ message }) {
					toast.error(message as string)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	const pasteButton = () => {
		navigator.clipboard.readText()
			.then(text => {
				console.log('Pasted content: ', text);
				form.setFieldsValue({
					'voucher': text
				})
			})
			.catch(err => {
				console.error('Failed to read clipboard contents: ', err);
			})
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'voucher': ''
			})
		}
	}, [form]) //eslint-disable-line

	return (
		<>
			<Modal
				className="ant-modal-deposit"
				open={isOpen}
				title="เติมเงินผ่านอังเปา"
				centered
				onCancel={() => !isSubmitting && onClose()}
				footer={[
					<Button
						key={'button-submit'}
						type="primary"
						shape="round"
						htmlType="submit"
						loading={isSubmitting}
						onClick={handleSubmit}
						style={{ marginTop: '-32px' }}
						block
					>
						ส่งลิงก์
					</Button>
				]}
			>
				<Form
					form={form}
					name="truewalletVoucherForm"
					layout="vertical"
					hideRequiredMark>
					<Text style={{ marginBottom: 8, display: 'block' }}>กรุณาวางลิงก์ที่คัดลอกจากทรูมันนี่วอลเล็ท</Text>
					<Form.Item>
						<Row gutter={8}>
							<Col style={{ flex: '1' }}>
								<Form.Item
									name="voucher"
									noStyle
									style={{ marginBottom: 0 }}
									rules={[{ required: true, message: 'กรุณากรอกลิงก์อั่งเปา' }]}
								>
									<Input inputMode='text' placeholder="กรอกลิงก์" style={{ paddingTop: '11.25px', paddingBottom: '11.25px' }} />
								</Form.Item>
							</Col>
							<Col>
								<Button type="primary" onClick={pasteButton}>วางลิงก์</Button>
							</Col>
						</Row>
					</Form.Item>
				</Form>
			</Modal>
			<Image
				width={200}
				style={{ display: 'none' }}
				src="https://msn.sgp1.cdn.digitaloceanspaces.com/cdn/images/truewallet-voucher-howto-min.jpg"
				preview={{
					visible,
					src: 'https://msn.sgp1.cdn.digitaloceanspaces.com/cdn/images/truewallet-voucher-howto-min.jpg',
					onVisibleChange: value => {
						setVisible(value);
					},
				}}
			/>
		</>
	)
}

export default TruewalletVoucher
