// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { redeemDeposit } from '../../services/deposit'
import { Button, Form, Modal, Input } from 'antd'

interface RedeemDialogProps {
	isOpen: boolean
	onClose: () => void
}
const RedeemDialog: FC<RedeemDialogProps> = ({ isOpen, onClose }) => {
	const [form] = Form.useForm();

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				try {
					const res = await redeemDeposit({
						code: values.redeemCode,
					})

					toast.success(res.message as string)
					onClose()
				} catch ({ message }) {
					toast.error(message as string)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'redeemCode': ''
			})
		}
	}, [form])

	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="เติมคูปอง"
			centered
			onCancel={() => !isSubmitting && onClose()}
			footer={[
				<Button
					type="primary"
					shape="round"
					htmlType="submit"
					loading={isSubmitting}
					onClick={handleSubmit}
					block
				>
					เติมคูปอง
				</Button>
			]}
		>
			<Form
				form={form}
				name="redeemDialogForm"
				layout="vertical"
				hideRequiredMark>
				<Form.Item
					name="redeemCode"
					label='รหัสคูปอง'
					style={{ marginBottom: 0 }}
					rules={[{ required: true, message: 'กรุณากรอกรหัสคูปอง' }]}>
					<Input placeholder="กรอกรหัสคูปอง" inputMode='text' />
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default RedeemDialog
