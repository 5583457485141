import QRCodeStyling from 'qr-code-styling'
import React, { FC, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
// import logo from '../../assets/images/logo.png'
import { Button, Input, Tooltip, Typography, theme } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { hexToHsl } from '../../helpers/hexToHsl'
import CoinIcon from '../Affiliate/CoinIcon'

const { Text } = Typography;
const { useToken } = theme;

interface AffiliateWidgetProps {
	affiliateCode: string
	affiliateLink: string
	member?: Member
	prefix?: string
}
const AffiliateWidget: FC<AffiliateWidgetProps> = ({
	affiliateCode,
	affiliateLink,
	member,
	prefix,
}) => {
	const { token } = useToken()
	const defalutColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 8}%)`
	const lightColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 30}%)`
	const qrRef = useRef(null)
	const [qrRender, setQrRender] = useState(false)

	const copyAffiliateLink = () => {
		navigator.clipboard.writeText(affiliateLink)
		toast.success('คัดลอกลิงก์ชวนเพื่อนสำเร็จ')
	}

	useEffect(() => {
		if (!qrRender && qrRef.current && prefix) {
			const qrCode = new QRCodeStyling({
				width: 150,
				height: 150,
				data: affiliateLink,
				// image: prefix !== 'VIPBET88' ? logo : '',
				margin: 0,
				type: "svg",
				qrOptions: {
					typeNumber: 0,
					mode: "Byte",
					errorCorrectionLevel: "Q"
				},
				imageOptions: {
					hideBackgroundDots: true,
					imageSize: 0.4,
					margin: 0
				},
				dotsOptions: {
					type: "rounded",
					gradient: {
						type: "linear",
						rotation: 0.8726646259971648,
						colorStops: [
							{
								offset: 0,
								color: lightColorPrimary
							},
							{
								offset: 1,
								color: defalutColorPrimary
							}
						]
					}
				},
				backgroundOptions: {
					color: token.colorBgContainer,
				},
				cornersSquareOptions: {
					type: "extra-rounded",
					gradient: {
						type: "linear",
						rotation: 1.5707963267948966,
						colorStops: [
							{
								offset: 0,
								color: lightColorPrimary
							},
							{
								offset: 1,
								color: defalutColorPrimary
							}
						]
					}
				},
				cornersDotOptions: {
					color: "#ffc600",
					gradient: {
						type: "linear",
						rotation: 1.5707963267948966,
						colorStops: [
							{
								offset: 0,
								color: lightColorPrimary
							},
							{
								offset: 1,
								color: defalutColorPrimary
							}
						]
					}
				}
			})

			qrCode.append(qrRef.current!)
			setQrRender(true)
		}
	}, [qrRender, affiliateLink, prefix])

	return (
		<div className="invite-card">
			<div className="invite-card-body">
				<div className="invite-qrcode-container">
					<CoinIcon className="small-coin" colorFrom={defalutColorPrimary} colorTo={lightColorPrimary}/>
					<CoinIcon className="medium-coin" colorFrom={defalutColorPrimary} colorTo={lightColorPrimary}/>
					<CoinIcon className="large-coin" colorFrom={defalutColorPrimary} colorTo={lightColorPrimary}/>
					<CoinIcon className="x-large-coin" colorFrom={defalutColorPrimary} colorTo={lightColorPrimary}/>
					<div className="invite-qrcode" ref={qrRef} style={{ background: token.colorBgContainer }} />
				</div>
				<div>
					<Text style={{ display: 'block', marginBottom: 8 }}>ลิงก์ชวนเพื่อน</Text>
					<Input.Group
						style={{ display: 'flex' }}
						compact
					>
						<Input
							defaultValue={affiliateLink}
							style={{ background: '#0d0f10', color: '#fff' }}
							disabled
						/>
						<Tooltip title="คัดลอกลิงก์" color="#0d0f10">
							<Button
								type='primary'
								icon={<CopyOutlined style={{ fontSize: 20 }} />}
								style={{ width: 64, height: 'auto' }}
								onClick={copyAffiliateLink}
							/>
						</Tooltip>
					</Input.Group>
				</div>
			</div>
		</div>
	)
}

export default AffiliateWidget
