import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { format } from '../../helpers/number'
import AuthService from '../../services/auth'
import { setUser } from '../../store/user/user.actions'
import { Tooltip, Typography } from 'antd'
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons'

const { Text } = Typography;

interface TurnBadgeProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
}

const TurnBadge: FC<TurnBadgeProps> = ({
	isTurnover,
	credit,
	totalTurn,
	currentTurnover,
}) => {
	if (isTurnover) {
		return (
			<div className="turn-badge">
				<Text>ยอดเทิร์นโอเวอร์</Text>
				<Text style={{ marginLeft: 6 }}>
					<strong>{format(currentTurnover)}</strong>{' '}
					<span>/ {format(totalTurn)}</span>
				</Text>
			</div>
		)
	}
	return (
		<div className="turn-badge">
			<Text>ยอดเทิร์น</Text>
			<Text style={{ marginLeft: 6 }}>
				<strong>{format(credit)}</strong>{' '}
				<span>/ {format(totalTurn)}</span>
			</Text>
		</div>
	)
}

interface BalanceWidgetProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
	isCashback?: boolean
}
const BalanceWidget: FC<BalanceWidgetProps> = ({
	isTurnover,
	credit,
	totalTurn,
	currentTurnover,
	isCashback
}) => {
	const dispatch = useDispatch()
	const [fetching, setFetching] = useState(false)

	const refetchCredit = async () => {
		if (fetching) {
			return
		}
		setFetching(true)

		try {
			const res = await AuthService.getMemberInfo(true)
			dispatch(setUser(res))
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setFetching(false)
		}
	}

	return (
		<div className="wallet-container">
			<span>
				<span style={{ opacity: 0.75 }}>ยอดเงินทั้งหมด</span>
				{fetching ? (
					<LoadingOutlined style={{ fontSize: 18, marginLeft: 6 }} />
				) : (
					<Tooltip
						placement="right"
						title={'อัพเดทยอดเงิน'}
						color='#121416'
					>
						<RedoOutlined
							className="redo-button"
							onClick={refetchCredit}
							style={{ fontSize: 18, marginLeft: 6 }}
						/>
					</Tooltip>
				)}
			</span>
			<div className="wallet-amount">฿ {format(credit)}</div>
			<TurnBadge
				isTurnover={isTurnover}
				credit={credit}
				totalTurn={totalTurn}
				currentTurnover={currentTurnover}
			/>
		</div>
	)
}

export default BalanceWidget
