import React, { FC, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import history from '../../helpers/history'
import { ApplicationState } from '../../store'
import { requestPrefix } from '../../store/prefix/prefix.actions'
import { Result, Row, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

ReactGA.initialize('UA-172529548-1')

const PrefixContainer: FC = ({ children }) => {
	const dispatch: ThunkDispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	useEffect(() => {
		const unsub = history.listen((loc) => {
			ReactGA.pageview(loc.pathname + loc.search)
		})
		return () => {
			unsub()
		}
	}, [])

	useEffect(() => {
		dispatch(requestPrefix())
	}, [dispatch])

	useEffect(() => {
		if (prefixState.isLoaded && prefixState.info) {
			document.title = prefixState.info.title

			const icon = document.querySelector(`link[rel="icon"]`)
			if (icon) {
				icon.setAttribute('href', prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo)
			}
		}
	}, [prefixState])

	if (!prefixState.isLoaded) {
		return (
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
			</Row>
		)
	}
	if (prefixState.isLoaded && !prefixState.info) {
		return (
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<Result
					status="warning"
					title="เว็บไซต์ดังกล่าวไม่สามารถใช้งานได้"
					subTitle="กรุณาตรวจสอบข้อมูลอีกครั้ง"
				/>
			</Row>
		)
	}
	if (prefixState.isLoaded && prefixState.info?.maintenance) {
		return (
			// <div className="center-container">
			// 	<i className="fad fa-engine-warning fa-3x" />
			// 	<h3 className="error-h3">เว็บไซต์อยู่ระหว่างการปรับปรุง</h3>
			// 	<h5 className="erorr-h5">กรุณาติดต่อผู้ดูแลระบบ</h5>
			// </div>
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<Space direction="vertical" align="center">
					<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="150" xmlns="http://www.w3.org/2000/svg" ><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#ffe59a" /><stop offset="1" stop-color="#ffffd5" /></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="105" y2="407"><stop offset="0" stop-color="#fd5900" /><stop offset="1" stop-color="#ffde00" /></linearGradient><g><g><circle cx="256" cy="256" fill="url(#SVGID_1_)" r="256" /></g><g><g><path d="m184.941 282.647h-53.294c-4.905 0-8.882 3.976-8.882 8.882v106.588c0 4.905 3.977 8.882 8.882 8.882h62.176v-115.47c.001-4.906-3.977-8.882-8.882-8.882zm-17.764 53.294c-4.905 0-8.882-3.978-8.882-8.882 0-4.907 3.977-8.882 8.882-8.882s8.882 3.976 8.882 8.882c0 4.905-3.977 8.882-8.882 8.882zm133.235-136.185v82.891h53.294v-82.891c15.863-9.232 26.647-26.227 26.647-45.903 0-16.989-7.999-34.033-20.407-46.282-5.6-5.53-15.122-1.549-15.122 6.32v31.08l-17.765 17.765-17.765-17.765v-31.08c0-7.871-9.522-11.849-15.122-6.32-12.408 12.249-20.407 29.292-20.407 46.282 0 19.676 10.784 36.67 26.647 45.903zm71.058 100.656h-79.941c-4.905 0-8.882-3.978-8.882-8.883v-35.529c0-4.906-3.977-8.882-8.882-8.882-14.717 0-26.647 11.93-26.647 26.647v26.647h-35.529v106.588h142.118c19.622 0 35.529-15.907 35.529-35.529v-53.294c-.001-9.812-7.954-17.765-17.766-17.765z" fill="url(#SVGID_2_)" /></g></g></g></svg>
					<h1 style={{ textAlign: 'center', color: "rgba(255, 255, 255, 0.85)" }}>ขณะนี้อยู่ระหว่างการปิดปรับปรุงอัพเดทระบบ</h1>
					<p style={{ textAlign: 'center', color: "rgba(255, 255, 255, 0.85)" }}>หากดำเนินการแก้ไขเสร็จเรียบร้อย จะแจ้งให้ทราบอีกครั้ง<br />ขออภัยในความไม่สะดวกค่ะ</p>
				</Space>
			</Row>
		)
	}
	return <>{children}</>
}

export default PrefixContainer
