import React, { FC } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { LeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

const { Text } = Typography

interface TopbarMobileProps {
	pathname: string
	providerName?: string
}

const TopbarMobile: FC<TopbarMobileProps> = ({ pathname, providerName }) => {
	const matchSlotPath: any = matchPath(pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	const history = useHistory()

	const linkToBack = () => {
		return matchSlotPath?.isExact
			? history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
			: history.goBack()
	}

	return (
		<div className="topbar-mobile">
			{pathname !== '/launch-game' && (
				<Text>
					<LeftOutlined
						onClick={() => linkToBack()}
						style={{ fontSize: 20 }}
					/>
				</Text>
			)}
			<div className="topbar-mobile-title">
				<Text>
					{providerName
						? providerName
						: (topbarText[pathname] ?? '-')
					}
				</Text>
			</div>
		</div>
	)
}

export default TopbarMobile
