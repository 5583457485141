import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'
import { isMobile } from '../../helpers/utils'

interface UsunGameProps {}

interface UsunLoginResponse {
	url: string
}

const UsunGame: FC<UsunGameProps> = () => {
	const history = useHistory()
	useEffect(() => {
		try {
			api.get<void, UsunLoginResponse>('/usun', {
				params: {
					mode: isMobile() ? 'm' : 'd',
				},
			}).then((res) => {
				window.location.href = res.url
			})
		} catch ({ message }) {
			toast.error(message as string)
			history.push(makePath('/wallet'))
		}
	}, [history])

	return <div>Redirecting ...</div>
}

export default UsunGame
