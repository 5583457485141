// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { Button, Form, InputNumber } from 'antd'

interface WithdrawFormProps {
	initAmount: number
}
const WithdrawForm: FC<WithdrawFormProps> = ({ initAmount }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				try {
					const res = await api.post<void, APIResponse>(
						'/member/withdraw',
						values
					)

					dispatch(requestUser())
					toast.success(res.message)
				} catch ({ message }) {
					toast.error(message as string)
				} finally {
					setSubmitting(false)
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': initAmount
			})
		}
	}, [form]) //eslint-disable-line

	return (

		<Form
			form={form}
			name="withdrawForm"
			layout="vertical"
			hideRequiredMark>
			<Form.Item
				name="amount"
				label="จำนวนเงินที่ต้องการถอน"
				rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการถอน' }]}
			>
				<InputNumber
					placeholder="จำนวนเงินที่ต้องการถอน"
					inputMode="numeric"
					controls={false}
					formatter={(value) => `฿ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
					style={{ width: '100%' }}
				/>
			</Form.Item>
			<div className="btn-submit">
				<Button type="primary" shape="round" htmlType="submit" loading={isSubmitting} onClick={handleSubmit} block>
					ยืนยันถอนเงิน
				</Button>
			</div>
		</Form>
	)
}

export default WithdrawForm
