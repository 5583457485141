export const Themes = [
    {
        key: 0,
        theme: {
            borderRadius: 10,
            colorBgBase: '#121416',
            colorBgContainer: '#0d0f10',
            colorBorder: '#434343',
            colorBorderSecondary: '#1b1d1f',
            colorLink: '#ffc800',
            colorLinkActive: '#dbac00',
            colorLinkHover: '#ffd333',
            colorPrimary: '#ffc800',
            colorTextDescription: 'rgba(255, 255, 255, 0.75)',
            controlHeight: 52,
            fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: 24,
            lineHeight: 1
        },
        other: {
            colorSecondary: '#5a028d'
        }
    },
    {
        key: 1,
        theme: {
            borderRadius: 10,
            colorBgBase: '#121416',
            colorBgContainer: '#0d0f10',
            colorBorder: '#434343',
            colorBorderSecondary: '#1b1d1f',
            colorLink: '#00ff61',
            colorLinkActive: '#00d95a',
            colorLinkHover: '#52ff89',
            colorPrimary: '#00ff61',
            colorTextDescription: 'rgba(255, 255, 255, 0.75)',
            controlHeight: 52,
            fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: 24,
            lineHeight: 1,
        },
        other: {
            colorSecondary: '#ffc800'
        }
    },
    {
        key: 2,
        theme: {
            borderRadius: 10,
            colorBgBase: '#121416',
            colorBgContainer: '#0d0f10',
            colorBorder: '#434343',
            colorBorderSecondary: '#1b1d1f',
            colorLink: '#005de7',
            colorLinkActive: '#00349c',
            colorLinkHover: '#52a3ff',
            colorPrimary: '#005de7',
            colorTextDescription: 'rgba(255, 255, 255, 0.75)',
            controlHeight: 52,
            fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: 24,
            lineHeight: 1,
        },
        other: {
            colorSecondary: '#c30000'
        }
    },
    {
        key: 3,
        theme: {
            borderRadius: 10,
            colorBgBase: '#121416',
            colorBgContainer: '#0d0f10',
            colorBorder: '#434343',
            colorBorderSecondary: '#1b1d1f',
            colorLink: '#9702ed',
            colorLinkActive: '#7700c7',
            colorLinkHover: '#cc54ff',
            colorPrimary: '#9702ed',
            colorTextDescription: 'rgba(255, 255, 255, 0.75)',
            controlHeight: 52,
            fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: 24,
            lineHeight: 1,
        },
        other: {
            colorSecondary: '#ffc800'
        }
    },
    {
        key: 4,
        theme: {
            borderRadius: 10,
            colorBgBase: '#121416',
            colorBgContainer: '#0d0f10',
            colorBorder: '#434343',
            colorBorderSecondary: '#1b1d1f',
            colorLink: '#f91f7e',
            colorLinkActive: '#ad0358',
            colorLinkHover: '#ff70a5',
            colorPrimary: '#f91f7e',
            colorTextDescription: 'rgba(255, 255, 255, 0.75)',
            controlHeight: 52,
            fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontSize: 24,
            lineHeight: 1,
        },
        other: {
            colorSecondary: '#5a028d'
        }
    },
]