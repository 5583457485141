import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import TruewalletQRDialog from '../components/Deposit/TruewalletQRDialog'
import TruewalletVoucher from '../components/Deposit/TruewalletVoucher'
import RedeemDialog from '../components/Deposit/RedeemDialog'
import UploadSlip from '../components/Deposit/UploadSlip'
import DepositMethodButton from '../components/Deposit/DepositMethodButton'
import { makePath } from '../helpers/path'
import { ApplicationState } from '../store'
import { requestDepositTo } from '../store/deposit/deposit.actions'
import { Col, Divider, Row, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography

const Deposit = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const depositState = useSelector((state: ApplicationState) => state.deposit)
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])

	const isBankEnable = depositState.depositToList.filter(({ bank }) => bank !== 'truewallet').length > 0
	const isTruewalletEnable = depositState.depositToList.filter(({ bank }) => bank === 'truewallet').length > 0
	const [openTruewalletQRDialog, setOpenTruewalletQRDialog] = useState(false)
	const [openTruewalletVoucher, setOpenTruewalletVoucher] = useState(false)
	const [openRedeemDialog, setOpenRedeemDialog] = useState(false)
	const [openUploadSlip, setOpenUploadSlip] = useState(false)

	if (!prefix || !depositState.isLoaded) {
		return (
			<Row justify="center" align="top" style={{ height: '100%' }}>
				<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
			</Row>
		)
	}

	return (
		<Fragment>
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					<div style={{ marginBottom: 16 }}>
						<Text>กรุณาเลือกวิธีการเติมเงิน</Text>
					</div>

					{isBankEnable && (
						<Link to={makePath('/deposit/bank')}>
							<DepositMethodButton
								icon="icons/3d/bank.svg"
								title="โอนผ่านธนาคาร"
							/>
						</Link>
					)}

					{isTruewalletEnable && (
						<Link to={makePath('/deposit/wallet')}>
							<DepositMethodButton
								icon="icons/3d/true-wallet.svg"
								title="โอนผ่านทรูมันนี่วอลเล็ท"
							/>
						</Link>
					)}

					{depositState.truewalletQREnable && (
						<div onClick={() => setOpenTruewalletQRDialog(true)}>
							<DepositMethodButton
								icon="icons/3d/qr-code.svg"
								title="เติมเงินผ่าน QR Code"
								subTitle="(ทรูมันนี่วอลเล็ท)"
							/>
						</div>
					)}

					{depositState.truewalletVoucherEnable && (
						<div onClick={() => setOpenTruewalletVoucher(true)}>
							<DepositMethodButton
								icon="icons/3d/voucher.svg"
								title="เติมเงินผ่านอังเปา"
								subTitle="(ทรูมันนี่วอลเล็ท)"
							/>
						</div>
					)}

					{depositState.ambPayEnable && (
						<Link to={makePath('/deposit/ambpay')}>
							<DepositMethodButton
								icon="icons/3d/qr-code.svg"
								title="เติมเงินผ่าน QR Code"
								subTitle="(ธนาคาร)"
							/>
						</Link>
					)}

					{depositState.redeemEnable && (
						<div onClick={() => setOpenRedeemDialog(true)}>
							<DepositMethodButton
								icon="icons/3d/coupon.svg"
								title="เติมคูปอง"
							/>
						</div>
					)}

					{(prefix?.functions.scanqr && info?.bankCode !== "SCB") && (
						<>
							<Divider />
							<Link to={makePath('/deposit/upload-slip')}>
								<DepositMethodButton
									icon="icons/3d/transfer-warning.svg"
									title="อัพโหลดสลิป"
									subTitle="สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ"
									secondary
								/>
							</Link>
						</>
					)}
				</Col>
			</Row>

			<TruewalletQRDialog isOpen={openTruewalletQRDialog} onClose={() => setOpenTruewalletQRDialog(false)} />
			<TruewalletVoucher isOpen={openTruewalletVoucher} onClose={() => setOpenTruewalletVoucher(false)} />
			<RedeemDialog isOpen={openRedeemDialog} onClose={() => setOpenRedeemDialog(false)} />
			<UploadSlip isOpen={openUploadSlip} onClose={() => setOpenUploadSlip(false)} />
		</Fragment>
	)
}

export default Deposit
