// import { useFormik } from 'formik'
import React, { FC, useState } from 'react'
import { Button, Modal, message, Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { InboxOutlined } from '@ant-design/icons'

interface UploadSlipProps {
	isOpen: boolean
	onClose: () => void
}

const UploadSlip: FC<UploadSlipProps> = ({ isOpen, onClose }) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [uploading, setUploading] = useState(false)

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const handleCancel = () => setPreviewVisible(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewVisible(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList as any)

	const handleUpload = () => {
		const formData = new FormData()
		fileList.forEach(file => {
			formData.append('files[]', file as RcFile)
		})
		setUploading(true)

		fetch('https://www.mocky.io/v2/5cc8019d300000980a055e76', {
			method: 'POST',
			body: formData,
		})
		.then(res => res.json())
		.then(() => {
			setFileList([])
			message.success('upload successfully.')
		})
		.catch(() => {
			message.error('upload failed.')
		})
		.finally(() => {
			setUploading(false)
		})
	}

	const handleRemove = () => setFileList([])

	const uploadButton = (
		<>
			<InboxOutlined style={{
				marginBottom: 8,
				fontSize: 48
			}} />
			<p className="text-primary">คลิกหรือลากไฟล์มาที่บริเวณนี้เพื่ออัปโหลด</p>
		</>
	)
	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="อัพโหลดสลิป"
			centered
			onCancel={() => !uploading && onClose()}
			footer={[
				<>
					{fileList.length >= 1 && (
						<Button
							style={{ marginBottom: 16 }}
							shape="round"
							onClick={handleRemove}
							block
						>
							ลบรูปภาพ
						</Button>
					)}
				</>,
				<Button
					type="primary"
					className="no-margin"
					shape="round"
					htmlType="submit"
					loading={uploading}
					onClick={handleUpload}
					block
				>
					อัพโหลดสลิป
				</Button>
			]}
		>
			<Upload
				className="ant-upload-drag-custom"
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
			>
				{fileList.length >= 1 ? null : uploadButton}
			</Upload>
			<Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img alt={previewTitle} src={previewImage} style={{ width: '100%' }}/>
			</Modal>
		</Modal>
	)
}

export default UploadSlip
