import actionCreatorFactory from 'typescript-fsa'
import { ThunkDispatch } from '../../@types/store'
import { fetchDepositToList, FetchDepositToListResponse } from '../../services/deposit'

const actionCreator = actionCreatorFactory('@@msn/deposit')

export const requestDepositToDone = actionCreator<FetchDepositToListResponse>('requestDepositToDone')
export const requestDepositToFailed = actionCreator('requestDepositToFailed')
export const requestDepositTo = () => async (dispatch: ThunkDispatch) => {
	try {
		const res = await fetchDepositToList()

		return dispatch(requestDepositToDone(res))
	} catch (e) {
		console.error(e)
		return dispatch(requestDepositToFailed())
	}
}
