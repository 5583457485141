import React, { FC, MouseEvent, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AMBKingLoginResponse, AMBKingConfig, AMBKingGameProviderGames } from '../../../entities/AMBKing'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Col, Row, Typography } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons';
// import { config } from 'process'

const { Title } = Typography

interface AMBKingSlotProps {
	games: AMBKingConfig | any
	provider: string
	gameType: string

	setProvider: (name: string) => void
	setLoading: (e: boolean) => void
}
const AMBKingGames: FC<AMBKingSlotProps> = ({ games, provider, gameType, setProvider, setLoading }) => {
	// const [redirecting, setRedirecting] = useState(false)
	const [configGames, setConfigGames] = useState<AMBKingGameProviderGames | any>(undefined)
	const [providerName, setProviderName] = useState('')

	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, AMBKingLoginResponse>('/ambking/login', {
				tab: gameType,
				gameID: gameID,
				isMobile: isMobile(),
				provider: provider,
			})
			window.location.href = res.uri
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (games && provider && gameType) {
			setConfigGames(games.find((game: any) => game.provider === provider))
			setProviderName(games.find((game: any) => game.provider === provider).providerName)
		}
	}, [games, provider, gameType])

	return (
		<Row gutter={[{ xs: 16, sm: 16, xl: 32 }, { xs: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }}>
			<Col span={24}>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 8
				}}>
					<Title level={5} style={{ color: '#fff', marginBottom: 0 }}>{providerName}</Title>
					<Button
						icon={<AppstoreOutlined />}
						onClick={() => setProvider('')}
					>
						<span>เลือกค่าย</span>
					</Button>
				</div>
			</Col>
			{configGames && Object(configGames.games).map((game: any) => (
				<Col xs={8} sm={8} md={6} xl={4} className="col-4 col-md-3 col-xl-2" key={`${game.id}-${game.gameName}`}>
					<a href="#amb-king" className="slot-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
						{game.image.vertical ? (
							<img src={game.image.vertical} alt={game.gameName} className="slot-card-img" />
						) : (
							<img src="/image-placeholder.png" alt={game.gameName} className="slot-card-img" />
						)}
						<span>{game.gameName}</span>
					</a>
				</Col>
			))}
		</Row>
	)
}

export default AMBKingGames
