export const CategoryGame = [
    {
        key: "all",
        title: "ทั้งหมด",
        image: "icons/3d/all.svg",
    },
    {
        key: "sport",
        title: "กีฬา",
        image: "icons/3d/sport.svg",
    },
    {
        key: "casino",
        title: "คาสิโน",
        image: "icons/3d/casino.svg",
    },
    {
        key: "slot",
        title: "สล็อต",
        image: "icons/3d/slot.svg",
    },
    {
        key: "poker",
        title: "เกมไพ่",
        image: "icons/3d/poker.svg",
    },
    {
        key: "lotto",
        title: "หวย",
        image: "icons/3d/lotto.svg",
    },
]

export const CategoryGameAMBKing = [
    {
        key: "all",
        title: "ทั้งหมด",
        image: "icons/3d/all.svg",
    },
    {
        key: "card",
        title: "การ์ด",
        image: "icons/3d/card.svg",
    },
    {
        key: "casino",
        title: "คาสิโน",
        image: "icons/3d/casino.svg",
    },
    {
        key: "fish",
        title: "ปลา",
        image: "icons/3d/fish.svg",
    },
    {
        key: "keno",
        title: "คีโน่",
        image: "icons/3d/keno.svg",
    },
    {
        key: "poker",
        title: "เกมไพ่",
        image: "icons/3d/poker.svg",
    },
    {
        key: "slot",
        title: "สล็อต",
        image: "icons/3d/slot.svg",
    },
    {
        key: "trade",
        title: "เทรด",
        image: "icons/3d/trade.svg",
    },
    {
        key: "esport",
        title: "อีสปอร์ต",
        image: "icons/3d/esport.svg",
    },
    {
        key: "sport",
        title: "กีฬา",
        image: "icons/3d/sport.svg",
    },
]