import React, { useState, useEffect, useCallback, Fragment, MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { AskmebetConfig, AskmebetLoginResponse } from '../entities/askmebet'
import { ApplicationState } from '../store'
import { makePath } from '../helpers/path'
import { toast } from 'react-toastify'
import { isMobile } from '../helpers/utils'
import api from '../helpers/api'

interface GamesParams {
	providerID: string
}

const Games = () => {
	const params = useParams<GamesParams>()
	const history = useHistory()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined)
	const [redirecting, setRedirecting] = useState(false)
	const member = userState.info!
	const prefix = prefixState.info!

	// Get askmebet config
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }) {
			toast.error(message as string)
			history.push(makePath('/wallet'))
		}
	}, [])

	// Slot login
	const triggerSlotLogin = (gameID: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()

		setRedirecting(true)
		try {
			const res = await api.post<void, AskmebetLoginResponse>('/askmebet/login/slot', {
				gameID: gameID,
				isMobile: isMobile(),
				productCode: params.providerID,
			})

			window.location.href = res.url
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setRedirecting(false)
		}
	}

	useEffect(() => {
		initAskmebetConfig()
	}, [initAskmebetConfig])

	if (!config) {
		return (<div className="loading-stage">
				<i className="fad fa-spinner fa-spin" />
			</div>)
	}

	if (!config.slot.games[params.providerID]) {
		history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
	}

	if (redirecting) {
		return null
	}

	const slotConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'slot')?.enable : true
	const dynamicEnabledSlot = Object.values(config.slot.config).filter((v) => v.enable).length > 0
	const games = config.slot.games[params.providerID]

	return (
		<Fragment>
			{slotConfig && dynamicEnabledSlot && member.game.meta['slot'] !== 'no' && (
				<Fragment>
					<div className="row my-3">
						{ games.filter((game) => game.name !== 'Bali Vacation')
							.map((game) => (
								<div className="col-4 col-md-3 col-xl-2">
									<a href="#ambbet-slot" className="slot-card" onClick={triggerSlotLogin(game.id)} key={game.id}>
										<img src={game.imageURL} alt={game.name} className="slot-card__img" />
										<span>{game.name}</span>
									</a>
								</div>
							))
						}
					</div>
				</Fragment>
			)}
		</Fragment>
	)
}

export default Games
