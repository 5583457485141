import React, { FC, useEffect, useState } from 'react'
import { Modal, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
// import { AspectRatio } from 'react-aspect-ratio';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface PopupProps {
    isOpen: boolean
    onClose: () => void
    data: any
}

const Popup: FC<PopupProps> = ({ isOpen, onClose, data }) => {

    const [dataPopup, setDataPopup] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)


    useEffect(() => {
        if (isOpen && Object.keys(data).length > 0) {
            let popupData = Object.values(data).map((element: any, idx: any) => {
                const item: any = element
                const keyPopup = Object.keys(data)[idx];
                item.key = keyPopup
                return item
            });
            setLoading(false)
            setDataPopup(popupData)
        }
    }, [isOpen, data])


    return (
        <Modal
            className="ant-modal-verify-phone"
            open={isOpen}
            title=""
            centered
            onCancel={onClose}
            // closable={false}
            footer={false}
        >
            {loading ?
                <Row justify="center" align="top" >
                    <LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
                </Row>
                :
                <Carousel
                    autoPlay={true}
                    showStatus={false}
                    showThumbs={false}
                    interval={4000}
                    infiniteLoop={true}
                >
                    {dataPopup.map((item: any, idx: any) => {
                        return (
                            <div className="test-slider" key={item.key}>
                                <img src={item.image} alt="banner" />
                            </div>
                        )
                    })}
                </Carousel>
            }


        </Modal >
    )
}

export default Popup
