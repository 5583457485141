import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
// import { Prefix } from '../../@types/prefix'
import { AMBKingConfig } from '../../entities/AMBKing'
import api from '../../helpers/api'
import AMBKingHasGame from './AMBKing/AMBKingHasGame'
import Games from './AMBKing/Games'
import SportGames from './AMBKing/SportGames'
import { Empty, Row, Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CategoryGameAMBKing } from '../../utils/categoryGame'
import type { TabsProps } from 'antd';

interface AMBKingProps {
	// prefix: Prefix
	member: Member
}

const AMBKing: FC<AMBKingProps> = ({ member }) => {
	//
	// { prefix, member }
	const [config, setConfig] = useState<AMBKingConfig | undefined>(undefined)
	const [provider, setProvider] = useState('')
	const [gameType, setGameType] = useState<string>('all')
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingAMBKing, setLoadingAMBKing] = useState<boolean>(false)

	const [allProvider, setAllProvider] = useState<any>(undefined)

	const initAMBKingConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AMBKingConfig>('/ambking')
			setConfig(res)
			setAllProvider(res.games)
			if (Object.values(res.games).length === 1) {
				setGameType(Object.keys(res.games)[0]);
			}
			setLoadingAMBKing(true)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		initAMBKingConfig()
		window.scrollTo(0, 0)
	}, [initAMBKingConfig])

	if (!config) {
		return (
			<div style={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}>
				<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
			</div>
		)
	}

	const handleGameType = (game: string) => {
		setProvider('')
		if (game !== 'all') {
			setGameType(game)
		}
	}

	const items: TabsProps['items'] | any = loadingAMBKing && CategoryGameAMBKing.filter((item) => {
		if (item.key !== "all") {
			if (Object(allProvider)[(item.key).toLowerCase()] === undefined) {
				return false; // skip
			}
		}
		if (item.key === "all" && Object.values(allProvider).length === 1) {
			return false; // skip
		}
		return true;
	}).map((x) => {
		return {
			key: x.key,
			label: (
				<div className='menu-tab' key={`menu-tab-${x.key}`}>
					<div className="icon">
						<img src={x.image} alt="nav" />
					</div>
					<span>
						<strong>{x.title}</strong>
					</span>
				</div>
			),
			children: (
				loading ? (
					<Row justify="center" align="middle" style={{ height: '100%', marginTop: '10px' }}>
						<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
					</Row>
				) : (
					<>
						{!provider ? (
							(x.key === 'all' && allProvider) ? (
								Object.values(allProvider).length > 0 ? (
									<div className='ambbet-provider-wrapper' key={`menu-children-${x.key}`}>
										<Row gutter={[{ xs: 12, sm: 12, md: 24 }, { xs: 12, sm: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }} key={`Row-${x.key}`}>
											{Object.values(allProvider).map((item: any, index: any) => {
												const gamesType = Object.entries(allProvider)[index][0]
												const gamesProvider = Object.entries(allProvider)[index][1]
												if (Object(allProvider)[(gamesType).toLowerCase()] === undefined) {
													return <></>
												}
												return (
													<AMBKingHasGame
														config={gamesProvider}
														type={gamesType}
														setProvider={setProvider}
														setGameType={setGameType}
													/>
												)
											})}
										</Row>
									</div>
								) : (
									<div style={{
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}>
										<Empty description="ไม่พบเกมส์" image={Empty.PRESENTED_IMAGE_SIMPLE} />
									</div>
								)
							) : (x.key !== 'all' && gameType !== 'all' && allProvider) && (
								(gameType === 'sport' && x.key === 'sport' ? (
									<SportGames
										setProvider={setProvider}
										setGameType={setGameType}
										gameType={gameType}
										setLoading={setLoading}
									/>
								) :
									(Object(allProvider)[(gameType).toLowerCase()] !== undefined ? (
										<div className='ambbet-provider-wrapper' key={`menu-children-${x.key}`}>
											<Row gutter={[{ xs: 12, sm: 12, md: 24 }, { xs: 12, sm: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }} key={`Row-${x.key}`}>
												<AMBKingHasGame
													config={allProvider[(gameType).toLowerCase()]}
													type={gameType}
													setProvider={setProvider}
													setGameType={setGameType}
												/>
											</Row>
										</div>
									) :
										<div style={{
											height: '100%',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center'
										}}>
											<Empty description="ไม่พบเกมส์" image={Empty.PRESENTED_IMAGE_SIMPLE} />
										</div>
									)
								)
							)
						) : (
							<div style={{ marginTop: 24 }} key={`games-${x.key}`}>
								<Games
									games={allProvider[gameType]}
									provider={provider as string}
									setProvider={setProvider}
									gameType={gameType}
									setLoading={setLoading}
								/>
							</div>
						)}
					</>
				)
			)
		}
	})

	return (
		<>
			<Tabs defaultActiveKey={items ? items[0].key : "all"} items={items} className="ambbet-tab ambking-tab" onChange={(e) => handleGameType(e)} />
		</>
	)
}

export default AMBKing
