import React, { ComponentType, FC } from 'react'
import store from '../../store'
import SetTheme from '../../utils/SetTheme'
import { Avatar, Row } from 'antd'

const AuthLayout = (Component: ComponentType<object>): FC => (props) => {
	const prefixState = store.getState().prefix

	return (
		<Row
			justify="center"
			align="middle"
			style={{ height: '100%' }}
		>
			<SetTheme />
			<div className="auth-container">
				{prefixState.info?.logo ? (
					<Avatar
						className="avatar-logo"
						src={prefixState.info?.logo}
						shape="square"
						size={125} />
				) : (
					<Avatar
						className="avatar-logo"
						shape="square"
						size={125}>
						{prefixState.info?.prefix}
					</Avatar>

				)}
				<Component {...props} />
			</div>
		</Row>
	)
}

export default AuthLayout
