import React, { ChangeEvent, FC } from 'react'
import { bankNames } from '../../helpers/bank'
import { Button, Form, Input, Select } from 'antd'
import PatternNumber from '../../helpers/PatternNumber'

interface RegisterStep2Props {
	bankCode: string
	isSubmitting: boolean

	setBankCode: (e: any) => void
	handleSubmit: (e: ChangeEvent<any>) => void
}

const RegisterStep2: FC<RegisterStep2Props> = ({
	bankCode,
	setBankCode,
	isSubmitting,
	handleSubmit,
}) => (
	<>
		<Form.Item
			name="bankCode"
			label="ธนาคาร"
			rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}
			hasFeedback>
			<Select
				placeholder="เลือกธนาคารของท่าน ..."
				showSearch
				optionFilterProp="children"
				filterOption={(input: any, option: any) =>
					option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				onSelect={setBankCode}>
				{Object.keys(bankNames).map((vBankCode) => (
					<Select.Option key={vBankCode} value={vBankCode}>{bankNames[vBankCode]}</Select.Option>
				))}
			</Select>
		</Form.Item>
		<Form.Item
			name="bankNumber"
			label="เลขที่บัญชี"
			rules={[{ required: true, message: 'กรุณากรอกเลขที่บัญชี' }, { pattern: PatternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }]}
			hasFeedback>
			<Input placeholder="เลขที่บัญชี" inputMode='numeric' />
		</Form.Item>
		<Form.Item
			name="firstName"
			label="ชื่อจริง"
			rules={[{ required: true, message: 'กรุณากรอกชื่อจริง' }, { pattern: /^[ก-๙ ]+$/g, message: 'ชื่อต้องเป็นภาษาไทยเท่านั้น' }]}
			hasFeedback>
			<Input placeholder="ชื่อจริง" inputMode='text' />
		</Form.Item>
		<Form.Item
			name="lastName"
			label="นามสกุล"
			rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }, { pattern: /^[ก-๙ ]+$/g, message: 'นามสกุลต้องเป็นภาษาไทยเท่านั้น' }]}
			hasFeedback>
			<Input placeholder="นามสกุล" inputMode='text' />
		</Form.Item>

		{bankCode === 'SCB' && (
			<>
				<Form.Item
					name="firstNameEN"
					label="ชื่อจริง (ภาษาอังกฤษ)"
					rules={[{ required: true, message: 'กรุณากรอกชื่อจริง (ภาษาอังกฤษ)' }, { pattern: /^[a-zA-Z ]+$/g, message: 'ชื่อต้องเป็นภาษาอังกฤษเท่านั้น' }]}
					hasFeedback>
					<Input placeholder="ชื่อจริง (ภาษาอังกฤษ)" inputMode='text' />
				</Form.Item>
				<Form.Item
					name="lastNameEN"
					label="นามสกุล (ภาษาอังกฤษ)"
					rules={[{ required: true, message: 'กรุณากรอกนามสกุล (ภาษาอังกฤษ)' }, { pattern: /^[a-zA-Z ]+$/g, message: 'นามสกุลต้องเป็นภาษาอังกฤษเท่านั้น' }]}
					hasFeedback>
					<Input placeholder="นามสกุล (ภาษาอังกฤษ)" inputMode='text' />
				</Form.Item>
			</>
		)}

		<Form.Item>
			<Button
				type="primary"
				htmlType="submit"
				shape="round"
				loading={isSubmitting}
				onClick={handleSubmit}
				block
			>
				ถัดไป
			</Button>
		</Form.Item>
	</>
)

export default RegisterStep2
