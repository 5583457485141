import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'

interface BetflixGameProps {}

interface BetflixLoginResponse {
	url: string
}

const BetflixGame: FC<BetflixGameProps> = () => {
	const history = useHistory()
	useEffect(() => {
		try {
			api.post<void, BetflixLoginResponse>('/login/betflix').then((res) => {
				window.location.href = res.url
			})
		} catch ({ message }) {
			toast.error(message as string)
			history.push(makePath('/wallet'))
		}
	}, [history])

	return <div>Redirecting ...</div>
}

export default BetflixGame
