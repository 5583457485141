// import { getDomainWithoutSubdomain } from "../utils/domainWithoutSubdomain"

export const generateQRCodeURL = (data: string) => {
	const APIEndPoint = process.env.REACT_APP_API_ENDPOINT || ''
	// let APIEndPoint = process.env.REACT_APP_API_ENDPOINT || ''
	// if (window.location.hostname !== 'localhost' &&
	// 	window.location.hostname !== "msn.bet" &&
	// 	window.location.hostname !== "msn1.bet" &&
	// 	window.location.hostname !== "msn2.bet") {
	// 	APIEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2` || ''
	// }
	// const APIEndPoint = `https://api.${getDomainWithoutSubdomain(window.location.host)}/v2` || ''
	return (APIEndPoint + '/generate-qr?data=' + encodeURIComponent(data)).replace('/v2/', '/')
}
