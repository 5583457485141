import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, matchPath } from 'react-router'
import { ApplicationState } from '../../store'
import BottomBar from './BottomBar'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import TopbarMobile from './TopbarMobile'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
import SetTheme from '../../utils/SetTheme'
import { toast } from 'react-toastify'
import { Layout, theme } from 'antd';

const { Content } = Layout;
const { useToken } = theme;

const AppLayout: FC = ({ children }) => {
	const { token } = useToken();
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const loc = useLocation()

	const matchSlotPath: any = matchPath(loc.pathname, {
		path: '/slot/:providerID',
		exact: true,
		strict: true
	})

	// Get provider name
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined)
	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		if (matchSlotPath?.isExact) {
			initAskmebetConfig()
		}
	}, [initAskmebetConfig])

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	return (
		<Layout>
			<SetTheme />
			<Sidebar
				prefix={prefixState.info}
				member={userState.info}
				showMenuSupport={showMenuSupport}
			/>
			<Layout>
				<div
					className="layout-header"
					style={{ borderBottom: '1px solid ' + token.colorBorderSecondary}}
				>
					<Topbar
						prefix={prefixState.info}
						member={userState.info}
						pathname={loc.pathname}
					/>
					{loc.pathname !== '/wallet' && (
						<TopbarMobile pathname={loc.pathname} />
					)}
				</div>
				<Content className="web-container">
					{children}
				</Content>
				<BottomBar member={userState.info} />
			</Layout>
		</Layout>
	)
}

export default AppLayout
