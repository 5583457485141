import React, { FC, MouseEvent, useEffect } from 'react'
import { toast } from 'react-toastify'
import { AMBKingLoginResponse } from '../../../entities/AMBKing'
import api from '../../../helpers/api'
import { isMobile } from '../../../helpers/utils'
import { Button, Result } from 'antd'
// import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
// import { config } from 'process'

// const { Title } = Typography
// const { confirm } = Modal;

interface AMBKingSlotProps {
	gameType: string

	setProvider: (name: string) => void
	setGameType: (name: string) => void
	setLoading: (e: boolean) => void
}
const AMBKingSportGames: FC<AMBKingSlotProps> = ({ gameType, setProvider, setGameType, setLoading }) => {
	const triggerSportLogin = (type: string) => async (e: MouseEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		try {
			// ----- เวลา login เข้าเล่นเกม ambking ใช้เส้นนี้นะครับ
			const res = await api.post<void, AMBKingLoginResponse>('/ambking/login', {
				tab: type,
				gameID: "",
				isMobile: isMobile(),
				provider: "",
			})
			window.location.href = res.uri
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(false)
			setProvider('')
			setGameType('all')
		}
	}


	useEffect(() => {
		if (gameType !== "sport") {
			setProvider('')
			setGameType('all')
		}
	}, [gameType]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Result
			icon={<img src={"icons/3d/sport.svg"} alt="nav" style={{ width: "60px" }} />}
			title="คุณต้องการเล่นเกมกีฬา?"
			subTitle=""
			extra={[
				<Button type="primary" key="game-sport" onClick={triggerSportLogin(gameType)}>
					ไปที่หน้าเกม
				</Button>,
			]}
		/>
	)
}

export default AMBKingSportGames
