import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import truewalletLogo from '../../assets/images/topup/truewallet.png'
import { Button, Col, Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons';

const { Text } = Typography

interface WalletDepositItemProps {
	bank: DepositBank
}
const WalletDepositItem: FC<WalletDepositItemProps> = ({ bank }) => {
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		toast.success('คัดลอกเลขที่บัญชีสำเร็จ')
	}

	return (
		<Col xs={12} sm={6} lg={8} xl={6} xxl={4}>
			<div className="bank-deposit-card">
				<div
					className="bank-card-logo"
					style={{
						backgroundColor: '#fff',
						boxShadow: '#fff 0 8px 12px -10px'
					}}
				>
					<img
						width={56}
						src={truewalletLogo}
						alt="ทรูมันนี่วอลเล็ท"
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>ทรูมันนี่วอลเล็ท</small></Text></div>
					<Text className="bank-deposite-account">{bank.accountNumber}</Text>
					<div><Text type="secondary"><small>{bank.accountName}</small></Text></div>
					<Button
						type="link"
						icon={<CopyOutlined />}
						onClick={copyAccountNumber}
					>
						คัดลอก
					</Button>
				</div>
			</div>
		</Col>
	)
}

export default WalletDepositItem
