import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { AskmebetConfig } from '../../entities/askmebet'
import api from '../../helpers/api'
// import AskmebetCasino from './Askmebet/Casino'
import AskmebetLink from './Askmebet/AmbmebetLink'
import AmbmebetHasGame from './Askmebet/AmbmebetHasGame'
import Games from './Askmebet/Games'
// import AskmebetSlot from './Askmebet/Slot'
// import AskmebetSlotByProvider from './Askmebet/SlotByBrand'
import { Row, Tabs } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CategoryGame } from '../../utils/categoryGame'
// import { AskmebetGameProviderConfig } from '../../entities/askmebet'

const { TabPane } = Tabs;

interface AskmebetProps {
	prefix: Prefix
	member: Member
}

const Askmebet: FC<AskmebetProps> = ({ prefix, member }) => {
	const [config, setConfig] = useState<AskmebetConfig | undefined>(undefined)
	const [provider, setProvider] = useState('')
	const [gameType, setGameType] = useState<string>('all')
	const [loading, setLoading] = useState<boolean>(false)

	const initAskmebetConfig = useCallback(async () => {
		try {
			const res = await api.get<void, AskmebetConfig>('/askmebet')

			setConfig(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}, [])

	useEffect(() => {
		initAskmebetConfig()
		window.scrollTo(0, 0)
	}, [initAskmebetConfig])

	if (!config) {
		return (
			<div style={{
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}>
				<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
			</div>
		)
	}

	const casinoConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'casino')?.enable : true
	const lottoConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'lotto')?.enable : true
	const pokerConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'poker')?.enable : true
	const kenoConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'keno')?.enable : true
	const slotConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'slot')?.enable : true
	const sportConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'sport')?.enable : true
	const esportConfig = prefix.ambbetGames ? prefix.ambbetGames.find((x) => x.game === 'esport')?.enable : true

	const dynamicEnabledCasino = Object.values(config.casino.config).filter((v) => v.enable).length > 0
	const dynamicEnabledLotto = Object.values(config.lotto.config).filter((v) => v.enable).length > 0
	const dynamicEnabledPoker = Object.values(config.poker.config).filter((v) => v.enable).length > 0
	const dynamicEnabledKeno = Object.values(config.keno.config).filter((v) => v.enable).length > 0
	const dynamicEnabledSlot = Object.values(config.slot.config).filter((v) => v.enable).length > 0
	const dynamicEnabledSport = Object.values(config.sport.config).filter((v) => v.enable).length > 0
	const dynamicEnabledEsport = Object.values(config.esport.config).filter((v) => v.enable).length > 0

	const handleGameType = (game: string) => {
		setProvider('')
		if (game !== 'all') {
			setGameType(game)
		}
	}

	const CategoryGameEnable = CategoryGame.map((x) => {
		if (x.key === 'all') {
			return x
		}

		const gameEnable = prefix.ambbetGames && prefix.ambbetGames.find((item) => item.game === x.key)?.enable
		if (gameEnable === true && (member.game.meta[x.key] !== undefined && member.game.meta[x.key] === 'yes')) {
			return x
		}

		return undefined
	})

	return (
		<>
			<Tabs
				defaultActiveKey="all"
				className="ambbet-tab"
				onChange={(e) => handleGameType(e)}
			>
				{CategoryGameEnable.filter((x) => x !== undefined).map((item: any, index: any) => (
					<TabPane
						tab={
							<div className='menu-tab'>
								<div className="icon">
									<img src={item.image} alt="nav" />
								</div>
								<span>
									<strong>{item.title}</strong>
								</span>
							</div>
						}
						key={item.key}
						forceRender={true}
					>
						{loading ?
							(
								<Row justify="center" align="middle" style={{ height: '100%' }}>
									<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
								</Row>
							) : (
								<>
									{/* Provider */}
									{!provider && (
										<div className='ambbet-provider-wrapper'>
											<Row gutter={[{ xs: 12, sm: 12, md: 24 }, { xs: 12, sm: 12, md: 24 }]} style={{ animation: 'fadeIn 0.5s' }}>
												{item.key === 'all' && (
													<>
														{/*
															เงื่อนไข: การแสดงผลแต่ละประเภทเกม
															1. ตั้งค่า เปิด/ปิด ประเภทจาก backoffice
															2. ประเภทเกมต้องมี provider ที่ enable === true มากกว่า 0 provider
															3. หาก user นััดรับโปรโมชั่นประเภทเกมใดเกมหนึ่งจะเปิดให้เล่นได้แค่ประเภทนั้น
															4. slot & poker ถ้า provider ไม่มีเกมไม่ต้องแสดง provider
														*/}
														{
															casinoConfig
															&& dynamicEnabledCasino
															&& (member.game.meta['casino'] !== undefined && member.game.meta['casino'] === 'yes')
															&& (<AskmebetLink config={config.casino.config} type={'casino'} setLoading={setLoading} />)
														}

														{
															lottoConfig
															&& dynamicEnabledLotto
															&& (member.game.meta['lotto'] !== undefined && member.game.meta['lotto'] === 'yes')
															&& (<AskmebetLink config={config.lotto.config} type={'lotto'} setLoading={setLoading} />)
														}

														{
															slotConfig
															&& dynamicEnabledSlot
															&& (member.game.meta['slot'] !== undefined && member.game.meta['slot'] === 'yes')
															&& (
																<AmbmebetHasGame
																	config={config.slot.config}
																	games={config.slot.games}
																	type={'slot'}
																	setProvider={setProvider}
																	setGameType={setGameType}
																/>
															)
														}
														{
															pokerConfig
															&& dynamicEnabledPoker
															&& (member.game.meta['poker'] !== undefined && member.game.meta['poker'] === 'yes')
															&& (
																<AmbmebetHasGame
																	config={config.poker.config}
																	games={config.poker.games}
																	setProvider={setProvider}
																	setGameType={setGameType}
																	type={'poker'}
																/>
															)
														}
														{
															kenoConfig
															&& dynamicEnabledKeno
															&& (member.game.meta['keno'] !== undefined && member.game.meta['keno'] === 'yes')
															&& (<AskmebetLink config={config.keno.config} type={'keno'} setLoading={setLoading} />)
														}
														{
															sportConfig
															&& dynamicEnabledSport
															&& (member.game.meta['sport'] !== undefined && member.game.meta['sport'] === 'yes')
															&& (<AskmebetLink config={config.sport.config} type={'sport'} setLoading={setLoading} />)
														}
														{
															esportConfig
															&& dynamicEnabledEsport
															&& (member.game.meta['esport'] !== undefined && member.game.meta['esport'] === 'yes')
															&& (<AskmebetLink config={config.esport.config} type={'esport'} setLoading={setLoading} />)
														}
													</>
												)}

												{item.key === 'casino' && (
													<>
														{
															casinoConfig
															&& dynamicEnabledCasino
															&& (member.game.meta['casino'] !== undefined && member.game.meta['casino'] === 'yes')
															&& (<AskmebetLink config={config.casino.config} type={'casino'} setLoading={setLoading} />)
														}
													</>
												)}

												{item.key === 'slot' && (
													<>
														{
															slotConfig
															&& dynamicEnabledSlot
															&& (member.game.meta['slot'] !== undefined && member.game.meta['slot'] === 'yes')
															&& (
																<AmbmebetHasGame
																	config={config.slot.config}
																	games={config.slot.games}
																	type={'slot'}
																	setProvider={setProvider}
																	setGameType={setGameType}
																/>
															)
														}
													</>
												)}

												{item.key === 'lotto' && (
													<>
														{
															lottoConfig
															&& dynamicEnabledLotto
															&& (member.game.meta['lotto'] !== undefined && member.game.meta['lotto'] === 'yes')
															&& (<AskmebetLink config={config.lotto.config} type={'lotto'} setLoading={setLoading} />)
														}
													</>
												)}

												{item.key === 'poker' && (
													<>
														{
															pokerConfig
															&& dynamicEnabledPoker
															&& (member.game.meta['poker'] !== undefined && member.game.meta['poker'] === 'yes')
															&& (
																<AmbmebetHasGame
																	config={config.poker.config}
																	games={config.poker.games}
																	setProvider={setProvider}
																	setGameType={setGameType}
																	type={'poker'}
																/>
															)
														}
														{
															kenoConfig
															&& dynamicEnabledKeno
															&& (member.game.meta['keno'] !== undefined && member.game.meta['keno'] === 'yes')
															&& (<AskmebetLink config={config.keno.config} type={'keno'} setLoading={setLoading} />)
														}
													</>
												)}
												{item.key === 'sport' && (
													<>
														{
															sportConfig
															&& dynamicEnabledSport
															&& (member.game.meta['sport'] !== undefined && member.game.meta['sport'] === 'yes')
															&& (<AskmebetLink config={config.sport.config} type={'sport'} setLoading={setLoading} />)
														}
														{
															esportConfig
															&& dynamicEnabledEsport
															&& (member.game.meta['esport'] !== undefined && member.game.meta['esport'] === 'yes')
															&& (<AskmebetLink config={config.esport.config} type={'esport'} setLoading={setLoading} />)
														}
													</>
												)}
											</Row>
										</div>
									)}
									{/* ถ้าเป็น Slot, Poker คลิกแล้วจะแสดงหน้าให้เลือกเกมต่อ */}
									{provider && gameType !== 'all' && (gameType === 'slot' || gameType === 'poker') && (
										<div style={{ marginTop: 24 }}>
											<Games
												games={config}
												provider={provider as string}
												setProvider={setProvider}
												gameType={gameType}
												setLoading={setLoading}
											/>
										</div>
									)}
								</>
							)}
					</TabPane>
				))}
			</Tabs>
		</>

		// ===== Old version =====
		// <Row gutter={{xs: 16, sm: 16, xl: 32}}
		// 	<Col xs={5} sm={4} xl={3}>
		// 		<div className="game-menu">
		// 			<div
		// 				className={classNames('game-menu-type', {
		// 					'active': tab === 'casino',
		// 				})}
		// 				onClick={() => setTab('casino')}
		// 			>
		// 				<img src={'icons/3d/casino.svg'} alt="casino" />
		// 				<div>คาสิโน</div>
		// 			</div>
		// 			<div
		// 				className={classNames('game-menu-type', {
		// 					'active': tab === 'slot',
		// 				})}
		// 				onClick={selectProvider}
		// 			>
		// 				<img src={'icons/3d/slot.svg'} alt="slot" />
		// 				<div>สล็อต</div>
		// 			</div>
		// 		</div>
		// 	</Col>
		// 	<Col xs={19} sm={20} xl={21} style={{ marginBottom: 32 }}>
		// 		{
		// 			casinoConfig
		// 			&& dynamicEnabledCasino
		// 			&& member.game.meta['casino'] !== 'no'
		// 			&& tab === 'casino'
		// 			&& (
		// 				<Fragment>
		// 					<AskmebetCasino config={config.casino.config} />
		// 				</Fragment>
		// 			)
		// 		}

		// 		{
		// 			slotConfig
		// 			&& dynamicEnabledSlot
		// 			&& member.game.meta['slot'] !== 'no'
		// 			&& tab === 'slot'
		// 			&& provider === ''
		// 			&& (
		// 				<Fragment>
		// 					<AskmebetSlotByProvider
		// 						config={config.slot.config}
		// 						games={config.slot.games}
		// 						setProvider={setProvider}
		// 					/>
		// 				</Fragment>
		// 			)
		// 		}

		// 		{
		// 			slotConfig
		// 			&& dynamicEnabledSlot
		// 			&& member.game.meta['slot'] !== 'no'
		// 			&& tab === 'slot'
		// 			&& provider !== ''
		// 			&& (
		// 				<Fragment>
		// 					<Games
		// 						games={config.slot.games}
		// 						provider={provider as string}
		// 						providerName={config.slot.config[provider as string].name}
		// 						setProvider={setProvider}
		// 					/>
		// 				</Fragment>
		// 			)
		// 		}
		// 	</Col>
		// </Row>
	)
}

export default Askmebet
