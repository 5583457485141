import React, { FC } from 'react'
import { Promotion } from '../../@types/prefix'
import { Button, Card, Col } from 'antd';

const { Meta } = Card;

interface WalletPromotionProps {
	data: Promotion

	acceptingBonus: string
	acceptBonus: (key: string) => void
}
const WalletPromotion: FC<WalletPromotionProps> = ({
	data,
	acceptingBonus,
	acceptBonus,
}) => (
	<Col md={12} xl={8}>
		<Card
			className="promotion-card"
			cover={
				<img
					src={data.image}
					alt={data.name}
				/>
			}
		>
			<Meta
				title={data.name}
				description={data.description}
			/>
			<div className="promotion-actions">
				<Button
					type="primary"
					shape="round"
					loading={acceptingBonus === data.key}
					onClick={() => acceptBonus(data.key)}
				>
					รับโบนัส
				</Button>
			</div>
		</Card>
	</Col>
)

export default WalletPromotion
