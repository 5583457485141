import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import AffiliateWidget from '../components/Wallet/AffiliateWidget'
import BalanceWidget from '../components/Wallet/BalanceWidget'
import CashbackWidget from '../components/Wallet/CashbackWidget'
import WalletMobileMenu from '../components/Wallet/WalletMobileMenu'
import WalletPromotionContainer from '../components/Wallet/WalletPromotionContainer'
import Popup from '../components/Popup'
import { ApplicationState } from '../store'
import { Row, Col } from 'antd'

const Wallet = () => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)

	const [openPopup, setOpenPopup] = useState(false)

	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)

			if (prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && localStorage.getItem('popup') !== "close") {
				setOpenPopup(true)
			}
		}

		const expirationTime = localStorage.getItem('popupExpiration');
		if (expirationTime && (new Date().getTime() > Number(expirationTime))) {
			localStorage.removeItem('popup');
			localStorage.removeItem('popupExpiration');
		}
	}, [prefixState])

	const closePopup = () => {
		setOpenPopup(false)
		const currentTime = new Date();
		currentTime.setHours(currentTime.getHours() + 1);
		// currentTime.setTime(currentTime.getTime() + 1 * 60 * 1000);
		const expirationTime = currentTime.getTime();
		localStorage.setItem('popup', "close")
		localStorage.setItem('popupExpiration', expirationTime.toString());
	}



	return (
		<>
			<Row gutter={32} style={{ marginBottom: 32 }}>
				<Col xs={24} xl={16}>
					<BalanceWidget
						isTurnover={prefixState.info?.isTurnover || false}
						credit={userState.credit}
						totalTurn={userState.info?.totalTurn || 0}
						currentTurnover={userState.currentTurnover}
						isCashback={userState.cashback.enable}
					/>

					{userState.cashback.enable && <CashbackWidget cashback={userState.cashback.total} />}
				</Col>
				<Col xs={24} xl={8}>
					<AffiliateWidget
						affiliateCode={userState.affiliate.code}
						affiliateLink={userState.affiliate.link}
						member={userState.info}
						prefix={prefixState?.info?.prefix}
					/>
				</Col>
			</Row>

			{/* Mobile menu */}
			<WalletMobileMenu
				lineLink={prefixState.info?.lineLink || ''}
				wheel={prefixState.info?.wheel.enable || false}
				commission={prefixState.info?.commission || false}
				showMenuSupport={showMenuSupport}
				ranking={prefixState.info?.functions.rank || false}
			/>

			{/* Promotion */}
			{(prefixState.info?.promotions.length || 0) > 0 && (
				<WalletPromotionContainer promotions={prefixState.info?.promotions} />
			)}

			{/* Popup */}
			{prefixState.info?.popup && (Object.keys(prefixState.info?.popup).length) > 0 && (
				<Popup data={prefixState.info?.popup} isOpen={openPopup} onClose={closePopup} />
			)}
		</>
	)
}

export default Wallet
