import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { format } from '../../helpers/number'
import { requestUser } from '../../store/user/user.actions'
import { Button, Typography } from 'antd'

const { Text } = Typography

interface CashbackWidgetProps {
	cashback: number
}
const CashbackWidget: FC<CashbackWidgetProps> = ({ cashback }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const acceptCashback = async () => {
		setLoading(true)

		try {
			const res = await api.post<void, APIResponse>('/member/cashback')

			dispatch(requestUser())
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="wallet-cashback">
				<div>
					<Text type="secondary">ยอดแคชแบ็ก</Text>
					<div className="wallet-cashback-amount">฿ {format(cashback)}</div>
				</div>
				<Button
					type="primary"
					shape="round"
					className="ant-btn-purple"
					loading={loading}
					onClick={acceptCashback}>
					<span>รับแคชแบ็ก</span>
				</Button>
			</div>
		</div>
	)
}

export default CashbackWidget
