import { DepositBank } from '../@types/prefix'
import api from '../helpers/api'

export interface FetchDepositToListResponse {
	truewalletQREnable: boolean
	truewalletVoucherEnable: boolean
	ambPayEnable: boolean
	redeemEnable: boolean;
	depositBanks: DepositBank[]
}
export const fetchDepositToList = () => api.get<void, FetchDepositToListResponse>('/deposit')

interface TruewalletQRDepositPayload {
	amount: number
}

interface TruewalletVoucherDepositPayload {
	voucher: string
}

interface TruewalletQRDepositResponse {
	data: {
		qrCode: string
		accountName: string
	}
}

interface TruewalletVoucherDepositResponse {
	message: string
}

interface RedeemDepositPayload {
	code: string
}

interface RedeemDepositResponse {
	message: string
}

export const truewalletQRDeposit = (payload: TruewalletQRDepositPayload) =>
	api.post<void, TruewalletQRDepositResponse>('/deposit/truewallet-qr', payload)

export const truewalletVoucherDeposit = (payload: TruewalletVoucherDepositPayload) =>
	api.post<void, TruewalletVoucherDepositResponse>('/deposit/truewallet-voucher', payload)

export const redeemDeposit = (payload: RedeemDepositPayload) =>
	api.post<void, RedeemDepositResponse>('/member/redeem', payload) // path api ของ redeem
