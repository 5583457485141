import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'
import { Col, Row, Typography } from 'antd'

const { Link, Text } = Typography

const Support = () => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const prefix = prefixState.info

    const [lineID, setLineID] = useState<string>("")
    const [instagram, setInstagram] = useState<string>("")

    useEffect(() => {
        if (prefix?.socialMedia) {
            if (prefix?.socialMedia.line) {
                const re = "line://ti/p/"
                setLineID(prefix?.socialMedia.line.replace(re, ""))
            }
            if (prefix?.socialMedia.instagram) {
                const re = "https://www.instagram.com/"
                const ig = prefix?.socialMedia.instagram.replace(re, "@")
                setInstagram(ig.replace("/", ""))
            }
        }
    }, [prefix])


    return (
        <Row justify="center">
            <Col xs={24} md={16}>
                <Row gutter={[{xs: 16, sm: 24}, {xs: 16, sm: 24}]}>
                    {prefix?.socialMedia && prefix?.socialMedia.phoneNumber &&
                        <Col xs={24} sm={12}>
                            <div className="contact-card">
                                <div className="contact-icon">
                                    <img src={`contacts/phone.svg`} alt="" />
                                </div>
                                <div className="contact-text">
                                    <Text>เบอร์โทรศัพท์</Text>
                                    <Link href={`tel:${prefix?.socialMedia.phoneNumber}`}>{prefix?.socialMedia.phoneNumber}</Link>
                                </div>
                            </div>
                        </Col>
                    }

                    {prefix?.socialMedia && prefix?.socialMedia.facebook &&
                        <Col xs={24} sm={12}>
                            <div className="contact-card">
                                <div className="contact-icon">
                                    <img src={`contacts/facebook.svg`} alt="" />
                                </div>
                                <div className="contact-text">
                                    <Text>Facebook</Text>
                                    <Link href={prefix?.socialMedia.facebook} target="_blank" rel="noreferrer">{prefix?.socialMedia.facebook}</Link>
                                </div>
                            </div>
                        </Col>
                    }

                    {prefix?.socialMedia && prefix?.socialMedia.line &&
                        <Col xs={24} sm={12}>
                            <div className="contact-card">
                                <div className="contact-icon">
                                    <img src={`contacts/line.svg`} alt="" />
                                </div>
                                <div className="contact-text">
                                    <Text>Line ID</Text>
                                    <Link href={prefix?.socialMedia.line} target="_blank" rel="noreferrer">{lineID}</Link>
                                </div>
                            </div>
                        </Col>
                    }

                    {prefix?.socialMedia && prefix?.socialMedia.instagram &&
                        <Col xs={24} sm={12}>
                            <div className="contact-card">
                                <div className="contact-icon">
                                    <img src={`contacts/ig.svg`} alt="" />
                                </div>
                                <div className="contact-text">
                                    <Text>Instagram</Text>
                                    <Link href={prefix?.socialMedia.instagram} target="_blank" rel="noreferrer">{instagram}</Link>
                                </div>
                            </div>
                        </Col>
                    }

                    {prefix?.socialMedia && prefix?.socialMedia.telegram &&
                        <Col xs={24} sm={12}>
                            <div className="contact-card">
                                <div className="contact-icon">
                                    <img src={`contacts/telegram.svg`} alt="" />
                                </div>
                                <div className="contact-text">
                                    <Text>Telegram</Text>
                                    <Link href={prefix?.socialMedia.telegram} target="_blank" rel="noreferrer">{prefix?.socialMedia.telegram}</Link>
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
            </Col>
        </Row>
    );
};

export default Support;
