import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Member } from '../../@types/member'
import { requestUserDone, requestUserFailed, setUser } from './user.actions'

export interface State {
	isLoaded: boolean
	info?: Member
	credit: number
	currentTurnover: number
	cashback: {
		enable: boolean
		available: boolean
		total: number
	}
	affiliate: {
		code: string
		link: string
	}
}

export const defaultState: State = {
	isLoaded: false,
	info: undefined,
	credit: 0,
	currentTurnover: 0,
	cashback: {
		enable: false,
		available: false,
		total: 0,
	},
	affiliate: {
		code: '',
		link: '',
	},
}

export default reducerWithInitialState<State>(defaultState)
	.cases([setUser, requestUserDone], (state, payload) => ({
		...state,
		isLoaded: true,
		info: payload.member,
		credit: payload.credit,
		currentTurnover: payload.currentTurnover,
		cashback: payload.cashback,
		affiliate: payload.affiliate,
	}))
	.case(requestUserFailed, (state) => ({
		...state,
		isLoaded: true,
		info: undefined,
	}))
	.default((state) => state)
