import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { DepositBank } from '../../@types/prefix'
import { getBankColor, getBankName } from '../../helpers/bank'
import { Button, Col, Typography } from 'antd'
import { CopyOutlined } from '@ant-design/icons';

const { Text } = Typography

interface BankDepositItemProps {
	bank: DepositBank
}
const BankDepositItem: FC<BankDepositItemProps> = ({ bank }) => {
	const copyAccountNumber = () => {
		navigator.clipboard.writeText(bank.accountNumber)
		toast.success('คัดลอกเลขที่บัญชีสำเร็จ')
	}

	return (
		<Col xs={12} sm={6} lg={8} xl={6} xxl={4}>
			<div className="bank-deposit-card">
				<div
					className="bank-card-logo"
					style={{
						backgroundColor: getBankColor(bank.bank),
						boxShadow: `${getBankColor(bank.bank)} 0 8px 12px -10px`
					}}
				>
					<img
						width={56}
						src={require(`../../assets/banks/${bank.bank.toLowerCase()}.svg`).default}
						alt={getBankName(bank.bank)}
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>{getBankName(bank.bank)}</small></Text></div>
					<Text className="bank-deposite-account">{bank.accountNumber}</Text>
					<div><Text type="secondary"><small>{bank.accountName}</small></Text></div>
					<Button
						type="link"
						icon={<CopyOutlined />}
						onClick={copyAccountNumber}
					>
						คัดลอก
					</Button>
				</div>
			</div>
		</Col>
	)
}

export default BankDepositItem
