import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ApplicationState } from '../../store'
import { Transaction } from '../../@types/transaction'
import { getBankName } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { makePath } from '../../helpers/path'
import StatusBadge from './StatusBadge'
import { Button, Empty, Tag, Typography } from 'antd'

const { Text } = Typography

interface WithdrawListProps {
	data: Transaction[]
}

const WithdrawList: FC<WithdrawListProps> = ({ data }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)
	const [showMenuSupport, setShowMenuSupport] = useState<boolean>(true)
	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)

			const isNullishSocialMedia = prefixState.info?.socialMedia ? Object.values(prefixState.info?.socialMedia).every(value => {
				if (value === "") {
					return true;
				}
				return false;
			}) : true;
			setShowMenuSupport(isNullishSocialMedia)
		}
	}, [prefixState])

	if (data.length <= 0) {
		return (
			<Empty
				description='คุณไม่มีรายการถอนเงิน'
				image={Empty.PRESENTED_IMAGE_SIMPLE}
			/>
		)
	}

	return (
		<>
			{data.map((tx) => (
				<div className="transaction-card" key={tx.id}>
					<div className="transaction-header">
						<Tag>{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</Tag>
						<StatusBadge status={tx.status} />
					</div>

					<div className="transaction-body">
						<div className="transaction-info">
							<div className="transaction-title">ถอนเงิน</div>
							<Text>ไปยัง {getBankName(tx.transferTo)}</Text>
						</div>
						<div className="transaction-title">฿ {format(tx.amount)}</div>
					</div>

					{tx.status === 'reject' && tx.remark
						? (
							<div className="transaction-footer">
								{showMenuSupportLine && (
									<a href={prefixState.info?.socialMedia['line']} className="btn btn--primary" target="_blank" rel="noreferrer">
										<Button type="primary" shape="round">
											<img src={'/icons/outline/operator.svg'} alt="" />
											<span>ติดต่อเจ้าหน้าที่</span>
										</Button>
									</a>
								)}

								{!prefix?.lineLink && !showMenuSupport && !showMenuSupportLine && (
									<NavLink to={makePath('/support')} className="btn btn--primary">
										<Button type="primary" shape="round">
											<img src={'/icons/outline/operator.svg'} alt="" />
											<span>ติดต่อเจ้าหน้าที่</span>
										</Button>
									</NavLink>
								)}

								{prefix?.lineLink && !showMenuSupportLine && (
									<a href={prefix.lineLink} className="btn btn--primary" target="_blank" rel="noreferrer">
										<Button type="primary" shape="round">
											<img src={'/icons/outline/operator.svg'} alt="" />
											<span>ติดต่อเจ้าหน้าที่</span>
										</Button>
									</a>
								)}
							</div>
						) : ''
					}
				</div>
			))}
		</>
	)
}

export default WithdrawList
