import React, { FC } from 'react'
import { Tag } from 'antd';

interface StatusBadgeProps {
	status: string
}
const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
	if (status === 'pending') {
		return (<Tag color="gold">รอดำเนินการ</Tag>)
	}
	if (status === 'reject') {
		return (<Tag color="red">รายการไม่สำเร็จ</Tag>)
	}
	if (status === 'bank_duplicate') {
		return (<Tag color="red">รายการฝากผิดพลาด</Tag>)
	}
	return (<Tag color="green">รายการสำเร็จ</Tag>)
}

export default StatusBadge
