import React, { FC } from 'react'
import { Promotion } from '../../@types/prefix'
import { Button, Card, Col, Radio } from 'antd';

const { Meta } = Card;

interface PromotionListItemProps {
	promotion: Promotion
	acceptingBonus: string

	acceptBonus: (v: string) => void
}
const PromotionListItem: FC<PromotionListItemProps> = ({
	promotion,
	acceptingBonus,

	acceptBonus,
}) => (
	<Col xs={24} sm={12} xxl={8}>
		<Card
			className="promotion-card"
			cover={<img src={promotion.image} alt={promotion.name} />}
		>
			<Meta
				title={promotion.name}
				description={promotion.description}
			/>
			{promotion.amount > 0 && (
				<div className="promotion-actions">
					<Radio value={promotion.key} style={{ alignItems: 'center' }}>รับโบนัสอัตโนมัติ</Radio>
					<Button
						type="primary"
						shape="round"
						loading={acceptingBonus === promotion.key}
						onClick={() => acceptBonus(promotion.key)}
					>
						รับโบนัส
					</Button>
				</div>
			)}
		</Card>
	</Col>
)

export default PromotionListItem
