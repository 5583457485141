import React, { FC } from 'react'
import imgBronze from '../../assets/images/rank/bronze.png'
import imgSilver from '../../assets/images/rank/silver.png'
import imgGold from '../../assets/images/rank/gold.png'
import imgPlatinum from '../../assets/images/rank/platinum.png'
import imgDiamond from '../../assets/images/rank/diamond.png'
import imgSupreme from '../../assets/images/rank/supreme.png'

interface IImageRank {
    rank: string
}
const ImageRank: FC<IImageRank> = ({ rank }) => {
    switch (rank) {
        case "silver":
            return (
                <img src={imgSilver} alt="silver" />
            )
        case "gold":
            return (
                <img src={imgGold} alt="gold" />
            )
        case "platinum":
            return (
                <img src={imgPlatinum} alt="platinum" />
            )
        case "diamond":
            return (
                <img src={imgDiamond} alt="diamond" />
            )
        case "supreme":
            return (
                <img src={imgSupreme} alt="supreme" />
            )

        default:
            return (
                <img src={imgBronze} alt="bronze" />
            )
    }
}

export default ImageRank