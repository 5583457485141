import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'

const Logout = () => {
	const history = useHistory()
	useEffect(() => {
		AuthService.revokeToken()

		history.push(makePath('/login'))
	}, [history])

	return <div>กำลังออกจากระบบ ...</div>
}

export default Logout
