import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { makePath } from '../../helpers/path'
import AuthService from '../../services/auth'
import { ApplicationState } from '../../store'
import { requestUserDone, requestUserFailed, } from '../../store/user/user.actions'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const AuthContainer: FC = ({ children }) => {
	const dispatch = useDispatch()
	const userState = useSelector((state: ApplicationState) => state.user)

	const requestAuthState = useCallback(async () => {
		try {
			const res = await AuthService.getMemberInfo()
			dispatch(requestUserDone(res))
		} catch {
			localStorage.removeItem('x-auth')
			dispatch(requestUserFailed())
		}
	}, [dispatch])
	useEffect(() => {
		requestAuthState()
	}, [requestAuthState])

	if (!userState.isLoaded) {
		return (
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<LoadingOutlined className="primary-color" style={{ fontSize: '64px' }} />
			</Row>
		)
	}
	if (userState.isLoaded && !userState.info) {
		return <Redirect to={makePath('/login')} />
	}
	return <>{children}</>
}

export default AuthContainer
