// import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { PatternFormat } from 'react-number-format';
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { makePath } from '../helpers/path'
import AuthService from '../services/auth'
import { setUser } from '../store/user/user.actions'
import { Button, Divider, Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../store'

const Login = () => {
	const [form] = Form.useForm();
	const [isSubmitting, setSubmitting] = useState<boolean>(false)


	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const prefix = prefixState.info

	const [showMenuSupportLine, setShowMenuSupportLine] = useState<boolean>(false)

	const dispatch = useDispatch()
	const history = useHistory()

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)

				try {
					const res = await AuthService.login({
						phone: values.phone.replace(/-/g, ''),
						password: values.password,
					})

					dispatch(setUser(res))
					history.push(makePath('/wallet'))
				} catch ({ message }) {
					toast.error(message as string)
					setSubmitting(false)
				} finally {
					setSubmitting(false)
				}
			}).catch();
	}

	useEffect(() => {
		if (prefixState.info) {
			const isNullishSocialMediaLine = prefixState.info?.socialMedia ?
				(Object.keys(prefixState.info?.socialMedia).find((item: any) => item === "line") && prefixState.info?.socialMedia.line !== "" ? true : false)
				: false;
			setShowMenuSupportLine(isNullishSocialMediaLine)
		}
	}, [prefixState])


	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'phone': '',
				'password': '',
			})
		}
	}, [form])

	return (
		<>
			<Form
				form={form}
				name="truewalletqrdialogForm"
				layout="vertical"
				hideRequiredMark
			>
				<Form.Item
					name="phone"
					label="เบอร์โทรศัพท์"
					rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
				>
					<PatternFormat
						customInput={Input}
						format="###-###-####"
						mask="_"
						type="tel"
						placeholder="เบอร์โทรศัพท์"
					/>
				</Form.Item>
				<Form.Item
					name="password"
					label="รหัสผ่าน"
					rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}>
					<Input.Password placeholder="รหัสผ่าน" inputMode='text' />
				</Form.Item>
				<Form.Item style={{ paddingTop: '12px' }}>
					<Button
						type="primary"
						htmlType="submit"
						shape="round"
						loading={isSubmitting}
						onClick={handleSubmit}
						block
					>
						เข้าสู่ระบบ
					</Button>
					<Divider plain>หรือ</Divider>
					<Link to={makePath('/register')}>
						<Button shape="round" block>สมัครสมาชิก</Button>
					</Link>
					{prefix?.lineLink && !showMenuSupportLine && (
						<Link to={{ pathname: prefix.lineLink }} target="_blank"  >
							<Button
								shape="round"
								block
								className="ant-btn-line"
								style={{ marginTop: '18px' }}
								icon={<i className="fa-brands fa-line" style={{ paddingRight: '10px', color: '#06C755' }}></i>}
							>
								<span style={{ marginTop: '2px' }}>ติดต่อแอดมิน</span>
							</Button>
						</Link>
					)}
					{showMenuSupportLine && (
						<Link to={{ pathname: prefixState.info?.socialMedia['line'] }} target="_blank"  >
							<Button
								shape="round"
								block
								className="ant-btn-line"
								style={{ marginTop: '18px' }}
								icon={<i className="fa-brands fa-line" style={{ paddingRight: '10px', color: '#06C755' }}></i>}
							>
								<span style={{ marginTop: '2px' }}>ติดต่อแอดมิน</span>
							</Button>
						</Link>
					)}
				</Form.Item>
			</Form>
		</>
	)
}

export default Login
