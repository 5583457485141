import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from '../../helpers/api'
import { makePath } from '../../helpers/path'

interface PGSlotResponse {
    token: string
    url: string
}
const MsnSlot = () => {
    const history = useHistory()
    const initMsnSlot = useCallback(async () => {
        try {
            const { url } = await api.post<void, PGSlotResponse>('/login/msnslot')

            if (url) {
                window.location.href = url
            } else {
                history.push(makePath('/wallet'))
            }
        } catch ({ message }) {
            toast.error(message)
            history.push(makePath('/wallet'))
        }
    }, [history])
    useEffect(() => {
        initMsnSlot()
    }, [initMsnSlot])

    return <div>Redirecting ...</div>
}

export default MsnSlot
