import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { Prefix } from '../../@types/prefix'
import { topbarText } from '../../constant'
import { makePath } from '../../helpers/path'
import { ApplicationState } from '../../store'
import { format } from '../../helpers/number'
import { Avatar, Button, Typography } from 'antd';
import { PlusCircleFilled, RightOutlined } from '@ant-design/icons';
import ImageRank from '../Ranking/ImageRank'

const { Text, Title } = Typography;

interface TopbarProps {
	prefix?: Prefix
	member?: Member

	pathname: string
}
const Topbar: FC<TopbarProps> = ({ prefix, member, pathname }) => {
	const loc = useLocation()
	const history = useHistory()
	const launchGame = () => {
		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}

		history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
	}

	const userState = useSelector((state: ApplicationState) => state.user)
	const firstName = member?.firstName as string
	const lastName = member?.lastName as string
	const charAvatar = firstName.substring(0, 1);

	const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

	return (
		<div className={`topbar ${pathname === '/wallet' ? '' : 'topbar-hidden'}`}>
			{pathname === '/wallet' && (
				<div className="topbar-logo">
					<Link to={makePath('/wallet')}>
						{prefix?.logo ? (
							<Avatar
								className="avatar-logo"
								src={prefix?.logo}
								shape="square"
							/>
						) : (
							<div className="prefix-name">{prefix?.prefix}</div>
						)}
					</Link>
				</div>
			)}
			<div className="topbar-welcome">
				<Title level={5} style={{ margin: 0 }}>{topbarText[loc.pathname] ?? `สวัสดี, คุณ${firstName} ${lastName}`}</Title>
			</div>
			<div className="topbar-member">
				<div className="topbar-profile">
					{prefix?.functions.rank ? (
						<div className="topbar-profile-rank">
							<ImageRank rank={myRank} />
						</div>
					) : (
						<div className="topbar-avatar">{charAvatar}</div>
					)}

					<div className="topbar-info">
						<Text>{firstName} {lastName}</Text>
						<div>
							<Text type="secondary">฿ {format(userState.credit)}</Text>
							<NavLink to={makePath('/deposit')} style={{ marginLeft: '6px' }}>
								<PlusCircleFilled />
							</NavLink>
						</div>
					</div>
				</div>
				<Button
					className="ant-btn-purple"
					shape="round"
					onClick={launchGame}
				>
					เข้าเล่นเกม <RightOutlined style={{ fontSize: 14 }} />
				</Button>
			</div>
		</div>
	)
}

export default Topbar
