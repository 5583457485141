import React, { FC, MouseEvent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { makePath } from '../../helpers/path'
import { useLocation } from 'react-router'

interface BottomBarProps {
	member: Member | undefined
}
const BottomBar: FC<BottomBarProps> = ({ member }) => {
	const history = useHistory()
	const loc = useLocation()

	const launchGame = (e: MouseEvent<any>) => {
		e.preventDefault()

		if (!member?.game.meta) {
			toast.error('กรุณาฝากเงินครั้งแรกเพื่อเข้าเล่นเกม')
			return
		}
		history.push(makePath('/launch-game', [`t=${Date.now() + 5000}`]))
	}

	return (
		<div className="bottombar-mobile">
			<ul className="nav-menu">
				<li className="nav-item">
					<Link
						to={makePath('/wallet')}
						className={`nav-link ${loc.pathname === '/wallet' && 'active'}`}
					>
						<svg height="512" viewBox="0 0 36 36" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m28.67 32.93h-21.34a5.33 5.33 0 0 1 -5.33-5.33v-19.2a5.34 5.34 0 0 1 5.33-5.33h22.4v2.13h-22.4a3.2 3.2 0 0 0 -3.2 3.2v19.2a3.2 3.2 0 0 0 3.2 3.2h21.34a3.2 3.2 0 0 0 3.2-3.2v-14.93a3.2 3.2 0 0 0 -3.2-3.2h-20.27v-2.14h20.27a5.33 5.33 0 0 1 5.33 5.34v14.93a5.33 5.33 0 0 1 -5.33 5.33z" /><path d="m24.4 19.07h4.27v2.13h-4.27z" /></svg>
						<span>กระเป๋า</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link
						to={makePath('/deposit')}
						className={`nav-link ${loc.pathname === '/deposit' && 'active'}`}
					>
						<svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m28.8 28.8h-25.6a3.2 3.2 0 0 1 -3.2-3.2v-10.67a3.2 3.2 0 0 1 3.2-3.2h25.6a3.2 3.2 0 0 1 3.2 3.2v10.67a3.2 3.2 0 0 1 -3.2 3.2zm-25.6-14.93a1.07 1.07 0 0 0 -1.07 1.07v10.66a1.07 1.07 0 0 0 1.07 1.07h25.6a1.07 1.07 0 0 0 1.07-1.07v-10.67a1.07 1.07 0 0 0 -1.07-1.07z" /><path d="m27.73 9.6h-23.46a1.07 1.07 0 1 1 0-2.13h23.46a1.07 1.07 0 1 1 0 2.13z" /><path d="m25.6 5.33h-19.2a1.07 1.07 0 1 1 0-2.13h19.2a1.07 1.07 0 1 1 0 2.13z" /><path d="m16 24.53a4.27 4.27 0 1 1 4.27-4.27 4.27 4.27 0 0 1 -4.27 4.27zm0-6.4a2.13 2.13 0 1 0 2.13 2.13 2.14 2.14 0 0 0 -2.13-2.13z" /></svg>
						<span>เติมเงิน</span>
					</Link>
				</li>
				<li className="nav-item middle-item">
					<a href="#game" className="nav-link" onClick={launchGame}>
						<div className="icon-button">
							<div className="icon-container">
								<img className="icon-center" src={`/icons/3d/dices.svg`} alt="dices icon"/>
							</div>
						</div>
						<span>เข้าเล่นเกม</span>
					</a>
				</li>
				<li className="nav-item">
					<Link
						to={makePath('/withdraw')}
						className={`nav-link ${loc.pathname === '/withdraw' && 'active'}`}
					>
						<svg height="512" viewBox="0 0 36 36" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m32.46 21-.65-.43a1.07 1.07 0 0 0 -1.1-.05l-8.5 4.61a1.67 1.67 0 0 1 -1.56 0 3.14 3.14 0 0 0 -1.5-3l-2.29-1.38a5.33 5.33 0 0 0 -5.42-.05l-4.11 2.43v-.86a1.07 1.07 0 0 0 -1.06-1.07h-3.2a1.07 1.07 0 0 0 -1.07 1.07v10.66a1.07 1.07 0 0 0 1.07 1.07h3.2a1.07 1.07 0 0 0 1.07-1.07h4.45.35l7.72.86a5.33 5.33 0 0 0 3.73-1l9-6.53a3.2 3.2 0 0 0 -.13-5.26zm-27.26 10.87h-1.07v-8.54h1.07zm26.12-7.3-9 6.53a3.18 3.18 0 0 1 -2.24.59l-7.72-.86a5.08 5.08 0 0 0 -.59 0h-4.44v-5.36a1.08 1.08 0 0 0 .46-.14l4.71-2.72a3.2 3.2 0 0 1 3.25 0l2.25 1.39a1 1 0 0 1 -.88 1.8l-2.61-1-.79 2 2.62 1a3.15 3.15 0 0 0 3.36-.68 3.79 3.79 0 0 0 3.53-.12l7.94-4.3a1.13 1.13 0 0 1 .59.94 1.07 1.07 0 0 1 -.44.92z" /><path d="m22.43 21.08h-2.13v-1.14h-1.74v-2.13h3.59a1.09 1.09 0 0 0 0-2.18h-1.43a3.22 3.22 0 0 1 -.35-6.43v-1.2h2.13v1.19h1.81v2.13h-3.59a1.09 1.09 0 0 0 0 2.18h1.44a3.22 3.22 0 0 1 .28 6.43z" /><path d="m9.45 18.33a12.56 12.56 0 1 1 24 0l-2-.64a10.43 10.43 0 1 0 -19.9 0z" /></svg>
						<span>ถอนเงิน</span>
					</Link>
				</li>
				<li className="nav-item">
					<Link
						to={makePath('/account')}
						className={`nav-link ${loc.pathname === '/account' && 'active'}`}
					>
						<svg height="512" viewBox="0 0 36 36" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m18 18c-4.15 0-7.37-4.64-7.37-8.63a7.37 7.37 0 1 1 14.74 0c0 3.99-3.22 8.63-7.37 8.63zm0-13.87a5.24 5.24 0 0 0 -5.24 5.24c0 2.92 2.42 6.5 5.24 6.5s5.24-3.57 5.24-6.5a5.24 5.24 0 0 0 -5.24-5.24z"/><path d="m30.8 34h-25.6a1.07 1.07 0 0 1 -1.07-1.07v-3.2a9.66 9.66 0 0 1 2.81-6.79 9.53 9.53 0 0 1 6.79-2.81 1.07 1.07 0 0 1 .76.31l2.76 2.76a1.1 1.1 0 0 0 1.51 0l2.76-2.76a1.07 1.07 0 0 1 .75-.31 9.53 9.53 0 0 1 6.79 2.81 9.66 9.66 0 0 1 2.81 6.79v3.2a1.07 1.07 0 0 1 -1.07 1.07zm-24.53-2.13h23.46v-2.14a7.47 7.47 0 0 0 -7-7.45l-1.8 1.8-.63.63a3.29 3.29 0 0 1 -4.52 0l-2.48-2.43a7.41 7.41 0 0 0 -4.85 2.18 7.51 7.51 0 0 0 -2.19 5.28z"/></svg>
						<span>บัญชี</span>
					</Link>
				</li>
			</ul>
		</div>
	)
}

export default BottomBar
