// import { useFormik } from 'formik'
import React, { FC } from 'react'
import { RightOutlined } from '@ant-design/icons';

interface DepositMethodButtonProps {
    icon: string
    title: string
    subTitle?: string
    secondary?: boolean
}

const DepositMethodButton: FC<DepositMethodButtonProps> = ({
    icon,
    title,
    subTitle,
    secondary
}) => {
    return (
        <div className={'deposit-method' + (secondary ? ' bg-secondary' : '')}>
            <div className="deposit-method-icon">
                <img src={icon} alt={title} />
            </div>
            <div className="deposit-method-name">
                <span>{title}</span>

                {subTitle && (
                    <>
                        <br />
                        <small>{subTitle}</small>
                    </>
                )}
            </div>
            <RightOutlined />
        </div>
    )
}

export default DepositMethodButton
