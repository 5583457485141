import React, { useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { format } from '../helpers/number'
import api from '../helpers/api'
// import { APIResponse } from '../@types/api'
import { toast } from 'react-toastify'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import ImageRank from '../components/Ranking/ImageRank'
import useSWR from 'swr'
interface IRanking {
	data: IRank
}

interface IRank {

	myRankPosition: number
	topRank: ITopRank[]
}

interface ITopRank {
	rank: {
		name: string
		point: number
	}
	firstName: string
}

const Ranking = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const userState = useSelector((state: ApplicationState) => state.user)
	const [listRanking, setListRanking] = useState<any>()
	const getRanking = async () => {
		try {
			const res = await api.get<void, IRanking>(`/member/toprank`)
			return res?.data
		} catch ({ message }) {
			toast.error(message as string)
			return []
		}
	}
	const { data } = useSWR([`/member/toprank`], () => getRanking(), { refreshInterval: 30000 });

	const myName: string = userState.info ? userState.info.firstName : ""
	const myRank: any = userState.info ? userState.info.rank : { name: "", point: 0 }

	useEffect(() => {
		if (data) {
			setListRanking(data);
		}
		return () => {

		}
	}, [data])


	if (!prefix?.functions.rank) {
		return <Redirect to="wallet" />
	}

	return (
		<Row justify="center">
			<Col xs={24} md={16} xl={12}>
				<Row gutter={[12, 16]}>
					{(listRanking !== undefined && listRanking?.topRank.length > 0) && (listRanking.topRank).map((item: any, index: number) => (
						<Col span={24} className="ranking-col" key={index}>
							<div className="ranking-card top-ten">
								<div className="ranking-card-num">{index + 1}</div>
								<div className="ranking-card-icon">
									<ImageRank rank={item?.rank.name} />
								</div>
								<div className="ranking-card-name">{item.firstName}</div>
								<div className="ranking-card-point">
									<img src={'/icons/gif/coin.gif'} alt="coin" />
									<span>{format(item?.rank.point ? item.rank.point : 0, 0)}</span>
								</div>
							</div>
						</Col>
					))}
					<Col span={24} className="ranking-col">
						<Divider style={{ margin: '16px 0 28px' }} />
						<div className="ranking-card">
							<div className="ranking-card-icon">
								<ImageRank rank={myRank.name} />
							</div>
							<div className="ranking-card-name">
								<div>{myName} {myRank.name}</div>
								<div><small>อันดับ {format(listRanking?.myRankPosition ? listRanking.myRankPosition : 0, 0)}</small></div>
							</div>
							<div className="ranking-card-point">
								<img src={'/icons/gif/coin.gif'} alt="coin" />
								<span>{format(myRank.point, 0)}</span>
							</div>
						</div>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Ranking
