// import { useFormik } from 'formik'
import React, { FC } from 'react'

interface Props {
    className: string
    colorFrom: string
    colorTo: string
}

const CoinIcon: FC<Props> = ({ className, colorFrom, colorTo }) => {
    return (
        <div className={className}>
            <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <linearGradient id="BG_COLOR" gradientUnits="userSpaceOnUse" x1="-696.5625" y1="1194.5625" x2="-664.5625" y2="1194.5625" gradientTransform="matrix(16 0 0 -16 11145 19369)">
                    <stop offset="0" stop-color={colorTo} />
                    <stop offset="1" stop-color={colorFrom} />
                </linearGradient>
                <circle cx="256" cy="256" r="256" fill="url(#BG_COLOR)" />
                <g>
                    <g>
                        <path fill="#000000" opacity="0.3" d="M309,397.9c-11,0-21.6,0-32.6,0c0-10.4,0-20.8,0-31.4c-7,0-13.8,0-21.1,0c0,10.2,0,20.8,0,31.4c-11.2,0-21.9,0-33.1,0
			c0-10.6,0-21,0-31.7c-17,0-33.3,0-50.1,0c0-9.1,0-17.8,0-27.4c4,0,7.8,0.2,11.7,0c7.5-0.3,10.1-2.6,10.7-10.1
			c0.3-2.9,0.3-5.6,0.3-8.5c0-34.2,0-68.3,0-102.6c0-3,0-5.9-0.3-9c-0.6-6.9-3.4-9.3-10.2-9.8c-2.1-0.2-4.2-0.2-6.2-0.2
			c-1.9,0-3.7,0-5.9,0c0-9,0-17.8,0-27c16.5,0,33,0,49.9,0c0-10.9,0-21.1,0-31.8c11.2,0,21.8,0,33,0c0,10.4,0,20.8,0,31.4
			c7.2,0,13.9,0,21.1,0c0-10.4,0-20.6,0-31.4c11,0,21.8,0,33,0c0,11.2,0,22.6,0,33.8c22.1,5.3,39.5,15.8,42.6,39.8
			c3.2,25.8-11.8,40.5-35.4,50.1c26.7,6.6,44.3,21,42.7,49.3c-1.6,30.2-22.6,44-49.9,50.7C309,375.2,309,386.4,309,397.9z
			M243.5,278.4c0,17.8,0,34.2,0,50.9c0,4.3,2.9,5.9,6.4,5.8c12.2-0.5,24.5-0.5,36.6-1.9c12.5-1.4,21.4-12.6,21.9-25.8
			c0.5-13.9-6.4-24-20-25.9C273.6,279.4,258.7,279.4,243.5,278.4z M243.2,249.4c12.5,0,25,0.3,37.3-0.2c4.6-0.2,9.6-2.1,13.8-4.3
			c9-4.8,11.4-13.3,10.2-22.9c-1-8.2-6.7-15.4-15.7-16.5c-15-1.8-30.2-2.1-45.6-3C243.2,219.4,243.2,234.6,243.2,249.4z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill="#ffffff" d="M297,385.9c-11,0-21.6,0-32.6,0c0-10.4,0-20.8,0-31.4c-7,0-13.8,0-21.1,0c0,10.2,0,20.8,0,31.4
			c-11.2,0-21.9,0-33.1,0c0-10.6,0-21,0-31.7c-17,0-33.3,0-50.1,0c0-9.1,0-17.8,0-27.4c4,0,7.8,0.2,11.7,0
			c7.5-0.3,10.1-2.6,10.7-10.1c0.3-2.9,0.3-5.6,0.3-8.5c0-34.2,0-68.3,0-102.6c0-3,0-5.9-0.3-9c-0.6-6.9-3.4-9.3-10.2-9.8
			c-2.1-0.2-4.2-0.2-6.2-0.2c-1.9,0-3.7,0-5.9,0c0-9,0-17.8,0-27c16.5,0,33,0,49.9,0c0-10.9,0-21.1,0-31.8c11.2,0,21.8,0,33,0
			c0,10.4,0,20.8,0,31.4c7.2,0,13.9,0,21.1,0c0-10.4,0-20.6,0-31.4c11,0,21.8,0,33,0c0,11.2,0,22.6,0,33.8
			c22.1,5.3,39.5,15.8,42.6,39.8c3.2,25.8-11.8,40.5-35.4,50.1c26.7,6.6,44.3,21,42.7,49.3c-1.6,30.2-22.6,44-49.9,50.7
			C297,363.2,297,374.4,297,385.9z M231.5,266.4c0,17.8,0,34.2,0,50.9c0,4.3,2.9,5.9,6.4,5.8c12.2-0.5,24.5-0.5,36.6-1.9
			c12.5-1.4,21.4-12.6,21.9-25.8c0.5-13.9-6.4-24-20-25.9C261.6,267.4,246.7,267.4,231.5,266.4z M231.2,237.4
			c12.5,0,25,0.3,37.3-0.2c4.6-0.2,9.6-2.1,13.8-4.3c9-4.8,11.4-13.3,10.2-22.9c-1-8.2-6.7-15.4-15.7-16.5c-15-1.8-30.2-2.1-45.6-3
			C231.2,207.4,231.2,222.6,231.2,237.4z"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default CoinIcon