import React, { ChangeEvent, FC } from 'react'
import { PatternFormat } from 'react-number-format'

import { Button, Form, Input } from 'antd'
interface RegisterStep1Props {
	isSubmitting: boolean

	handleSubmit: (e: ChangeEvent<any>) => void
}
const RegisterStep1: FC<RegisterStep1Props> = ({
	isSubmitting,
	handleSubmit,
}) => (
	<>
		<Form.Item
			name="phone"
			label="เบอร์โทรศัพท์"
			rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
			hasFeedback
		>
			<PatternFormat
				customInput={Input}
				format="###-###-####"
				mask="_"
				type="tel"
				placeholder="เบอร์โทรศัพท์"
			/>
		</Form.Item>
		<Form.Item>
			<Button
				type="primary"
				htmlType="submit"
				shape="round"
				loading={isSubmitting}
				onClick={handleSubmit}
				block
			>
				ถัดไป
			</Button>
		</Form.Item>
	</>
)

export default RegisterStep1
