import { FC } from 'react'
import { ConfigProvider as ThemeProviders, theme } from 'antd'
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Themes } from '../../utils/Theme';
const { darkAlgorithm } = theme

interface Props {
    children: React.ReactNode;
}

const ConfigProvider: FC<Props> = ({ children }) => {

    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const themeSetting = Themes.filter(item => item.key === prefixState?.info?.themeID)[0].theme

    return (
        <ThemeProviders
            theme={{
                algorithm: darkAlgorithm,
                hashed: false,
                token: themeSetting
                // token: {
                //     borderRadius: 10,
                //     colorBgBase: '#121416',
                //     colorBgContainer: '#0d0f10',
                //     colorBorder: '#434343',
                //     colorBorderSecondary: '#1b1d1f',
                //     colorLink: '#ffc800',
                //     colorLinkActive: '#dbac00',
                //     colorLinkHover: '#ffd333',
                //     colorPrimary: '#ffc800',
                //     colorTextDescription: 'rgba(255, 255, 255, 0.75)',
                //     controlHeight: 52,
                //     fontFamily: "'DB HelvethaicaX', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                //     fontSize: 24,
                //     lineHeight: 1
                // }
            }}>
            {children}
        </ThemeProviders>
    )
}

export default ConfigProvider