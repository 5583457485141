import React, { FC } from 'react'
import { AMBKingGameProviderConfig } from '../../../entities/AMBKing'
import { Col } from 'antd'

interface AMBKingHasGameProps {
    config: Record<string, AMBKingGameProviderConfig> | any
    type: string

    setProvider: (name: string) => void
    setGameType: (name: string) => void
}
const AMBKingHasGame: FC<AMBKingHasGameProps> = ({ config, type, setProvider, setGameType }) => {
    return (
        <>
            {Object.values(config).map((itemProvider: any, indexProvider: any) => (
                (itemProvider.games.length > 0 && Object.values(itemProvider.games).filter((item: any) => item.status === 'ACTIVE').length) && (
                    <Col xs={6} sm={4} key={`Col-${indexProvider}`}>
                        <div className='ambking-provider-card'
                            onClick={() => {
                                setProvider(itemProvider.provider)
                                setGameType(type)
                            }}>
                            <div className='ambking-provider-img'>
                                {itemProvider.logoMobileURL ? (
                                    <img src={itemProvider.logoMobileURL} alt={itemProvider.providerName} />
                                ) : (
                                    <div className="provider-name-title">{ itemProvider.providerName }</div>
                                )}
                            </div>
                        </div>
                    </Col>
                )
            ))}
        </>
    )
}

export default AMBKingHasGame
